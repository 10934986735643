import React, { useState, useEffect } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Row, Col, Card, InputGroup, FormControl, Button, Image, Container, Accordion } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { ModalBoletas } from './modal-boletas';
import { searchNumber } from '../../../services/rifa.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Desencrypt } from '../../../utilities/cipher';
import { Transaction } from '../../../services/product.service';
import { FormatMoney } from '../../../utilities/formats';
import { ManagmentErrors } from '../../../shared/components/managmentErrors';
import { LoadingMovilGo } from '../../../shared/components/loadingMovilGo';
import { ModalNotificationTransaction } from '../../../shared/components/modalNotificationTransaction';
import { jsPdfGenerator } from '../../../shared/components/pdfTransaction';
import './styles.css';
import { Url_Home, Url_pago, idCallcenter, idCardoso, idCardosoVendedor, idMobilgoVendedor, idPadreRata, idvendedorqr } from '../../../utilities/odoo-config';
import { ListNumberTicketLandinPage } from '../../../shared/components/list-prices';

const SeleccionarBoleta = (props) => {
    console.log("datos del landing page", props.datos);
    console.log("datos rifa", JSON.parse(localStorage.getItem('datosRifa')));
    const datos = props.datos
    const partnerId = datos.partner_id
    const userTicket = datos.identificacion + " - " + datos.nombre
    const identificacion = datos.identificacion
    const telefono = datos.telefono
    const email = datos.correo
    const fullNameUser = datos.nombre + ' ' + datos.apellido
    const [boleta, setBoleta] = useState('');
    const [firstLoad, setFirstLoad] = useState(true);
    const [visibleRegistro, setVisibleRegistro] = useState(false);
    const [visibleBoletas, setVisibleBoletas] = useState(false);
    const [numberTicket, setNumberTicket] = useState('');
    const [numberTicketSearch, setNumberTicketSearch] = useState('');
    const [active, setActive] = useState(<></>);
    const [styleTicket, setStyleTicket] = useState({});
    const [viewTicket, setViewTicket] = useState(false);
    const [prices, setPrices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [opacity, setOpacity] = useState(1);
    const [modalNotification, setModalNotification] = useState(false);
    const [luckyNumber, setLuckyNumber] = useState(false);
    const [rifa, setRifa] = useState(JSON.parse(localStorage.getItem('datosRifa')));
    const [idProduct, setIdProduct] = useState(-1);
    const [showComprar, setShowComprar] = useState(true);
    const [numbersTicket, setNumbersTicket] = useState([]);
    const [whoPress, setwhoPress] = useState('');
    const [numbersTicketAvailable, setNumbersTicketAvailable] = useState([]);
    const [numbersTicketlucky, setNumbersTicketLucky] = useState([]);
    const [numSuerte, setNumSuerte] = useState(0);
    const session = Desencrypt();
    const idPadre = localStorage.getItem('idPadre');
    const [activeSection, setActiveSection] = useState('buscadorNumero');

    useEffect(() => {
        const idPadre = localStorage.getItem('idPadre');
        const datoSession = Desencrypt();
        if (
            datoSession.id === idCardosoVendedor ||
            datoSession.id === idMobilgoVendedor ||
            datoSession.id === idCallcenter ||
            datoSession.id === idvendedorqr
        ) {
            setShowComprar(false);
        } else {
            setShowComprar(true);
        }

        const initializeComponent = async () => {
            numeroDeLaSuerte(false);
            await new Promise(resolve => setTimeout(resolve, 200)); // Add a delay if needed
            numeroDeLaSuerte(true);
            setFirstLoad(false);
        };

        if (firstLoad) {
            initializeComponent();
        }

    }, []);

    const selectTicket = (number, selector) => {
        setNumberTicket(number);
        props.numberTicket(number);
        if (selector == 'numeroJugar') {
            setwhoPress('numeroJugar')
        } else if (selector == 'numSuerte') {
            setwhoPress('numSuerte')
        } else {
            setwhoPress('')
        }
    };

    const showModal = (flag) => {
        setVisibleRegistro(flag);
        setBoleta('');
        setVisibleBoletas(flag);
    };

    const chooseTicket = (flag) => {
        setVisibleBoletas(flag);
        setStyleTicket({ borderWidth: 2, borderColor: '#07A2BA' });
        setViewTicket(true);
    };

    const foundNumber = (response, flag) => {
        const encontrada = () => toast.info('¡Se encontró la boleta solicitada!');
        const boletasRelacionadas = () => toast.warning('¡No se encontró la boleta solicitada, pero hay algunas con relación!');
        const boletasNumSuerte = () => toast.info(`Tu número de la suerte es el ${numSuerte}, éstas son tus sugerencias`);

        if (flag) {
            let esta = false;
            let boletaNoEncontrada = false
            let newNumber = [];
            setIdProduct(response.disponibles[0].product_id);

            if (luckyNumber) {
                setNumbersTicketLucky([response.disponibles[0]]);
            } else {
                for (let k = 0; k < 6; k = k + 1) {
                    newNumber.push(response.disponibles[k]);
                }
                setNumbersTicket(newNumber);
                setNumbersTicketAvailable(newNumber)
            }

            if (response?.errores) {
                alert('Se produjo un error al consultar la boleta');
                return;
            } else if (response.disponibles.length > 0) {
                if (luckyNumber) {
                    setVisibleBoletas(true);
                    setLoading(false);
                    setOpacity(1);
                } else {
                    for (let index = 0; index < response.disponibles.length; index++) {
                        if (numberTicket == response.disponibles[index].numero_boleta) {
                            esta = true;
                        }
                        if (numberTicketSearch !== '' && numberTicketSearch !== response.disponibles[index].numero_boleta) {
                            boletaNoEncontrada = true
                        } else if (numberTicketSearch == response.disponibles[index].numero_boleta) {
                            esta = true;
                            boletaNoEncontrada = false
                        }

                    }
                    if (boletaNoEncontrada) {
                        boletasRelacionadas()
                    }

                    if (esta) {
                        encontrada();
                        setStyleTicket({ borderWidth: 2, borderColor: '#07A2BA' });
                        setViewTicket(true);
                        setLoading(false);
                        setOpacity(1);
                    } else {
                        setVisibleBoletas(true);
                        setLoading(false);
                        setOpacity(1);
                    }
                }
            }
        }
    };

    const searchTicket = () => {
        const errorDigitar = () => toast.error('¡No se le olvide digitar un número de boleta!');
        const rifa = JSON.parse(localStorage.getItem('datosRifa'));
        setNumbersTicket([]);
        setNumbersTicketLucky([]);
        setNumberTicket('');
        props.numberTicket('');
        if (numberTicketSearch.trim() !== '') {
            setLoading(true);
            setOpacity(0.4);
            searchNumber(numberTicketSearch, rifa.id, foundNumber);
        } else {
            errorDigitar();
        }
    };

    const numeroDeLaSuerte = (flag) => {
        setLuckyNumber(flag);
        const min = 1;
        const max = 99;
        const numeroAleatorio = Math.floor(Math.random() * (max - min)) + min;
        setNumSuerte(numeroAleatorio);
        if (flag) {
            setNumbersTicketLucky([])
            setNumberTicketSearch('')
            setNumberTicket('');
            props.numberTicket('');
        } else {
            setNumbersTicket([]);
            setNumberTicketSearch('')
            setNumberTicket('');
            props.numberTicket('');
        }
    };


    useEffect(() => {

        const delayedSearch = () => {
            const searchTicket = () => {
                const rifa = JSON.parse(localStorage.getItem('datosRifa'));

                if (numSuerte !== 0) {
                    setLoading(true);
                    setOpacity(0.4);
                    searchNumber(numSuerte, rifa.id, foundNumber);
                }
            };

            searchTicket();
        };

        const delay = setTimeout(delayedSearch, 100);

        return () => clearTimeout(delay);
    }, [numSuerte]);

    const handleChange = (event) => {
        setNumSuerte(0);
        setNumberTicketSearch(event.target.value.replace(/\D/, ''));
        setStyleTicket({ borderWidth: 1 });
        setViewTicket(false);
        setLuckyNumber(false)
        setNumbersTicketLucky([])
        setNumbersTicket([]);
        setNumbersTicketAvailable([])
        setNumberTicket('');
        props.numberTicket('');
    };

    const generatedLink = async () => {
        const idPadre = localStorage.getItem('idPadre');
        const session = await Desencrypt();
        console.log('id del vendedor', session.id);
        const urlPago = `${Url_pago}PagosExternos/?${idProduct}&&${session.id}&&${numberTicket}&&${rifa.precio}&&${partnerId}&&${userTicket.trim()}&&${rifa.name.trim()}&&${rifa.resolucion}&&${rifa.sorteo}&&${rifa.precio}&&${identificacion}&&${telefono}&&${email}&&${rifa.operador_efectivo}&&${fullNameUser}`;
        console.log('jejeje envio', urlPago.replace(/\s+/g, ''));
        window.open(urlPago.replace(/\s+/g, ''), '_blank');
        window.location.href = Url_Home;
    };

    const toggleSection = (section) => {
        setActiveSection(prev => (prev === section ? null : section)); // Abre una sección, cierra la otra
    };

    const accordionBodyStyle = {

    };

    const headerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
        backgroundColor: '#f8f9fa',
        cursor: 'pointer',
        borderRadius: 8,
        borderBottom: '3px solid #ddd',
        borderTop: '1px solid #ddd',
    };

    const buttonStyle = {
        textDecoration: 'none',
        fontWeight: 'bold',
        color: 'gray',
        fontSize: '16px', // Tamaño ajustado para dispositivos móviles
    };

    const iconStyle = {
        fontSize: '16px',
        transition: 'transform 0.3s ease',
    };



    return (
        <Container fluid>
            <Row className="justify-content-center">
                <div className="text-container">
                    <h2 className={'defaultTitle'} >BOLETAS DISPONIBLES</h2>
                    <h6>Elije tu número de boleta con algunas de las 3 opciones</h6>
                </div>
            </Row>

            {/* Primera sección: Siempre visible */}
            <div style={{ marginBottom: '10px' }}>
                <Accordion activeKey={activeSection === 'buscadorNumero' ? '0' : null}>
                    <div style={{ marginBottom: 20 }}>
                        <div style={headerStyle} onClick={() => toggleSection('buscadorNumero')}>
                            <Button variant="link" style={buttonStyle}>
                                Buscar tu número preferido
                            </Button>
                            {activeSection === 'buscadorNumero' ? (
                                <FaChevronUp style={iconStyle} />
                            ) : (
                                <FaChevronDown style={iconStyle} />
                            )}
                        </div>
                        <Accordion.Collapse eventKey="0">
                            <div style={accordionBodyStyle}>
                                <Row className="justify-content-center">
                                    <Col md={12}>
                                        <ToastContainer autoClose={2000} />
                                        <div style={{ marginTop: "2%" }}>
                                            <div className="custom-search-bar">
                                                <FormControl
                                                    style={{ width: '100%', borderRadius: '20px' }}
                                                    type="number"
                                                    placeholder="Busca tu número preferido"
                                                    aria-label="Número de la boleta"
                                                    value={numberTicketSearch}
                                                    onChange={handleChange}
                                                />
                                                <Button className="custom-search-bar button" onClick={searchTicket}>
                                                    Buscar
                                                </Button>
                                            </div>
                                            {numberTicketSearch && (
                                                <ListNumberTicketLandinPage
                                                    active={(flag) => setActive(flag)}
                                                    selectTicket={(number) => selectTicket(number)}
                                                    selectedTicket={numberTicket}
                                                    numbers={numbersTicketAvailable}
                                                />
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Collapse>
                    </div>
                </Accordion>

                {/* Sección Números para Jugar */}
                <Accordion activeKey={activeSection === 'numeroJugar' ? '1' : null}>
                    <div style={{ marginBottom: 20 }}>
                        <div style={headerStyle} onClick={() => toggleSection('numeroJugar')}>
                            <Button variant="link" style={buttonStyle}>
                                Generar Números aleatorios para tu juego
                            </Button>
                            {activeSection === 'numeroJugar' ? (
                                <FaChevronUp style={iconStyle} />
                            ) : (
                                <FaChevronDown style={iconStyle} />
                            )}
                        </div>
                        <Accordion.Collapse eventKey="1">
                            <div style={accordionBodyStyle}>
                                <Row className="justify-content-center">
                                    <Col md={12}>
                                        <div className="d-flex align-items-center align-items-center">
                                            <Button onClick={() => numeroDeLaSuerte(false)} variant="info" style={{ marginTop: '10px', marginRight: '10%' }}>
                                                Nuevos números
                                            </Button>
                                            {numberTicket && whoPress === 'numeroJugar' ? (
                                                <ListNumberTicketLandinPage
                                                    active={(flag) => setActive(flag)}
                                                    selectTicket={(number) => selectTicket(number)}
                                                    selectedTicket={numberTicket}
                                                    numbers={numbersTicket}
                                                />
                                            ) : null}
                                            {!numberTicket && !numberTicketSearch && (
                                                <ListNumberTicketLandinPage
                                                    active={(flag) => setActive(flag)}
                                                    selectTicket={(number) => selectTicket(number, 'numeroJugar')}
                                                    selectedTicket={numberTicket}
                                                    numbers={numbersTicket}
                                                />
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Collapse>
                    </div>
                </Accordion>

                {/* Sección Número de la Suerte */}
                <Accordion activeKey={activeSection === 'numSuerte' ? '2' : null}>
                    <div>
                        <div style={headerStyle} onClick={() => toggleSection('numSuerte')}>
                            <Button variant="link" style={buttonStyle}>
                                Generar Número de la suerte
                            </Button>
                            {activeSection === 'numSuerte' ? (
                                <FaChevronUp style={iconStyle} />
                            ) : (
                                <FaChevronDown style={iconStyle} />
                            )}
                        </div>
                        <Accordion.Collapse eventKey="2">
                            <div style={accordionBodyStyle}>
                                <Row className="justify-content-center">
                                    <Col md={12}>
                                        <div className="d-flex align-items-center align-items-center">
                                            <Button onClick={() => numeroDeLaSuerte(true)} variant="info">
                                                Generar nuevo
                                            </Button>
                                            <div style={{ marginLeft: '28%' }}>
                                                {numberTicket && whoPress === 'numSuerte' ? (
                                                    <ListNumberTicketLandinPage
                                                        active={(flag) => setActive(flag)}
                                                        selectTicket={(number) => selectTicket(number)}
                                                        selectedTicket={numberTicket}
                                                        numbers={numbersTicketlucky}
                                                    />
                                                ) : null}
                                                {!numberTicket && (
                                                    <ListNumberTicketLandinPage
                                                        active={(flag) => setActive(flag)}
                                                        selectTicket={(number) => selectTicket(number, 'numSuerte')}
                                                        selectedTicket={numberTicket}
                                                        numbers={numbersTicketlucky}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Collapse>
                    </div>
                </Accordion>
            </div>


            {/* <Row className="justify-content-center">
                <Col md={12}>
                    <div className="justify-content-md-center ">
                        {numberTicket &&
                            <Button onClick={generatedLink} variant={'info'} style={{ marginTop: "5%" }}> Generar pago </Button>}
                    </div>
                </Col>
            </Row> */}

            <Col md={12}>
                {loading && <LoadingMovilGo />}
                {modalNotification && <ModalNotificationTransaction closeModal={(flag) => setModalNotification(flag)} type={"Rifa"} message={"Rifa comprada exitosamente"} />}
            </Col>
        </Container>
    );


};



export default SeleccionarBoleta;