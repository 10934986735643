import React, { useState, useEffect } from 'react';
import { Button, InputGroup, FormControl, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { Transaction } from '../../../services/product.service';
import { FormatMoney } from '../../../utilities/formats';

export const ExequialComprar = () => {
    const [state, setState] = useState({
        Nombre: '',
        Apellido: '',
        Documento: '',
        Telefono: '',
        termsChecked: false,
        loading: false,
        validation: {
            Nombre: null,
            Apellido: null,
            Documento: null,
            Telefono: null,
        },
        seguro: JSON.parse(localStorage.getItem('datosSeguro'))
    });

    useEffect(() => {
        console.log("Datos recuperados:", state.seguro);
    }, []);

    const handleInputChange = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
            validation: {
                ...prevState.validation,
                [field]: value ? 'is-valid' : 'is-invalid',
            },
        }));
    };

    const handleCheckboxChange = (value) => {
        setState((prevState) => ({
            ...prevState,
            termsChecked: value,
            validation: {
                ...prevState.validation,
                terms: value ? 'is-valid' : 'is-invalid',
            },
        }));
    };

    const verifyFields = () => {
        const { Nombre, Apellido, Documento, Telefono } = state;
        const isFormValid = Nombre && Apellido && Documento && Telefono;

        setState((prevState) => ({
            ...prevState,
            validation: {
                ...prevState.validation,
                Nombre: Nombre ? 'is-valid' : 'is-invalid',
                Apellido: Apellido ? 'is-valid' : 'is-invalid',
                Documento: Documento ? 'is-valid' : 'is-invalid',
                Telefono: Telefono ? 'is-valid' : 'is-invalid',
            },
        }));

        if (isFormValid) {
            Swal.fire({
                title: "¿Los Datos Son Correctos?",
                showDenyButton: true,
                confirmButtonText: "Comprar",
                denyButtonText: `No Comprar`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //setState({ loading: true });
                    sendToOdoo();
                } else if (result.isDenied) {
                    Swal.fire("No Se Envío La Información", "", "info");
                }
            });
            // Realizar la lógica adicional aquí si es necesario
        } else {
            // Lógica adicional para manejar el caso en que el formulario no sea válido
            Swal.fire("Por favor, complete todos los campos requeridos.");
        }
    };

    const sendToOdoo = async () => {
        try {
            const product = {
                product_id: state.seguro.id,
                atributes: {
                    nombre: state.Nombre,
                    apellido: state.Apellido,
                    documento: state.Documento,
                    telefeono: state.Telefono,
                },
            };

            console.log('product del seguro ', product.atributes);
            //setState({ loading: true });
            await Transaction(product, navigateNext)
            // Asegúrate de tener acceso a navigateNext
        } catch (error) {
            // Maneja errores adecuadamente
            alert('Error: Problemas al obtener datos de sesión');
        }
    };
    const navigateNext = (flag, response) => {
        let data = [];
        if (flag) {
          data = [
            'Factura No: ' + response.valida.id,
            'Fecha:' + response.valida.fecha,
            'No.Aprobacion:' + response.valida.numero_aprobacion,
            'Producto: ' + this.state.rifa.name,
            'Resolucion: ' + this.state.rifa.numero_resolucion_rifa,
            'Fecha del sorteo: ' + this.state.rifa.fecha_sorteo,
            'Numero de boleta: ' + this.state.number,
            'Cliente: ' + this.state.cedula,
            'Valor: ' + FormatMoney(this.state.rifa.price),
          ];
          if (this.state.rifa.price > this.state.payedValue) {
            data = [
              'Factura No: ' + response.valida.id,
              'Fecha:' + response.valida.fecha,
              'No.Aprobacion:' + response.valida.numero_aprobacion,
              'Producto: Abono ' + this.state.rifa.name,
              'Resolucion: ' + this.state.rifa.numero_resolucion_rifa,
              'Fecha del sorteo: ' + this.state.rifa.fecha_sorteo,
              'Numero de boleta: ' + this.state.number,
              'Cliente: ' + this.state.cedula,
              'Valor total: ' + FormatMoney(this.state.rifa.price),
              'Valor abonado: ' + FormatMoney(parseInt(this.state.payedValue)),
              'Valor por pagar: ' +
              FormatMoney(this.state.rifa.price - this.state.payedValue),
            ];
          }
    
          this.setState({ loading: false });
          this.setState({ modalShared: true, dataResponse: data })
    
        } else {
          if (response) {
            if (response.errores) {
              if (response.errores.observacion) {
                Swal.fire('Error ', response.errores.observacion);
                //this.setState({ loading: false });
              } else {
                Swal.fire('Error ', 'No se pudo relizar la transaccion');
                //this.setState({ loading: false });
              }
            } else {
              Swal.fire('Error ', 'Se ha presentado un error de conexion');
              //this.setState({ loading: false });
            }
          }
        }
      };

    return (
        <>
            <div className="container">
                <div>
                    <h1>Comprar Seguro</h1>
                </div>
                <Form className="row g-3 justify-content-center">
                    {/* Nombre */}
                    <div className="col-md-8">
                        <Form.Label htmlFor="validationCustom01" className="form-label">Ingrese Nombre</Form.Label>
                        <InputGroup hasValidation>
                            <FormControl
                                type="text"
                                className={`form-control ${state.validation.Nombre}`}
                                id="validationCustom01"
                                value={state.Nombre}
                                onChange={(e) => handleInputChange('Nombre', e.target.value)}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor, ingrese un nombre válido.
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="valid">¡Correcto!</Form.Control.Feedback>
                        </InputGroup>
                    </div>
                    {/* Apellido */}
                    <div className="col-md-8">
                        <Form.Label htmlFor="validationCustom02" className="form-label">Ingrese Apellido</Form.Label>
                        <InputGroup hasValidation>
                            <FormControl
                                type="text"
                                className={`form-control ${state.validation.Apellido}`}
                                id="validationCustom02"
                                value={state.Apellido}
                                onChange={(e) => handleInputChange('Apellido', e.target.value)}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor, ingrese un apellido válido.
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="valid">¡Correcto!</Form.Control.Feedback>
                        </InputGroup>
                    </div>
                    {/* Identificación */}
                    <div className="col-md-8">
                        <Form.Label htmlFor="validationCustomUsername" className="form-label">Identificación</Form.Label>
                        <InputGroup hasValidation>
                            <FormControl
                                type="number"
                                className={`form-control ${state.validation.Documento}`}
                                id="validationCustomUsername"
                                value={state.Documento}
                                onChange={(e) => handleInputChange('Documento', e.target.value)}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor, ingrese una identificación válida.
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="valid">¡Correcto!</Form.Control.Feedback>
                        </InputGroup>
                    </div>
                    {/* Teléfono */}
                    <div className="col-md-8 mb-3">
                        <Form.Label htmlFor="validationCustom03" className="form-label">
                            Ingrese Teléfono
                        </Form.Label>
                        <InputGroup hasValidation>
                            <FormControl
                                type="number"
                                className={`form-control ${state.validation.Telefono}`}
                                id="validationCustom03"
                                value={state.Telefono}
                                onChange={(e) => handleInputChange('Telefono', e.target.value)}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor, ingrese un número de teléfono válido.
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="valid">¡Correcto!</Form.Control.Feedback>
                        </InputGroup>
                    </div>

                    <div className="col-12">
                        <Button
                            className="btn btn-primary"
                            type="button"
                            disabled={state.loading}
                            onClick={() => verifyFields()}>
                            Comprar
                        </Button>
                    </div>

                </Form>

                <div style={{ marginTop: '100px' }}>
                    <p></p>
                </div>
            </div>
        </>
    );
};
