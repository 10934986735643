import React, { PureComponent } from 'react';
import { LoadingMovilGo } from '../../shared/components/loadingMovilGo';
import CarruselRifas from './components/carousel-rifas';
import { ToastContainer, toast } from 'react-toastify';
import RegistroCliente from './components/modal-registro';
import './components/styles.css';
import { searchClient } from '../../services/cliente.service';
import { Button, Form } from 'react-bootstrap';
import DatosCliente from './components/datosComprador';
import SeleccionarBoleta from './components/selectorBoletas';
import { PagosExternosScreen } from '../pay/Pago';
import { Desencrypt } from '../../utilities/cipher';
import { urlHomeVendedor } from '../../utilities/odoo-config';

class LandingPage extends PureComponent {
    constructor(props) {
        super(props);

        const productos = localStorage.getItem('listProducts');
        let listProducts = null;

        if (productos) {
            try {
                listProducts = JSON.parse(productos);
            } catch (error) {
                console.error('Error parsing JSON from localStorage', error);
            }
        }

        console.log("listProducts", listProducts);

        // Verifica si listProducts es válido antes de acceder a rifas
        this.rifas = this.props.rifas || (listProducts ? listProducts.rifas : []);

        this.firstSectionRef = React.createRef();
        this.secondSectionRef = React.createRef();
        this.thirdSectionRef = React.createRef();
        this.fourthSectionRef = React.createRef();

        this.state = {
            userLogin:{},
            loading: true,
            compraRealizada: false,
            visible: false,
            isCliente: false,
            closeRegistrer: false,
            opacity: 1,
            partner_id: -1,
            identification: '',
            nombre: '',
            apellido: '',
            direccion: '',
            email: '',
            celular: '',
            numberTicket: '',
            selectedRifa: {
                id: -1,
                image_medium: null,
                name: '',
                precio: '',
                fecha_sorteo: '',
                porcentaje_recaudo: '',
                resolucion: '',
                operador_efectivo: '',
                operador_tarjeta: '',
            }
        };
    }

    componentDidMount = () => {
        const user= Desencrypt()
        console.log('usuer',user);

        if (this.rifas) {
            this.setState({ loading: false, userLogin: user });
        }
        
        function isSamsungBrowser() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            
            // Verificar si el userAgent contiene "SamsungBrowser"
            return /SamsungBrowser/i.test(userAgent);
        }
    
        if (isSamsungBrowser()) {

        // Construye el URL dinámico
        const url = `intent://${urlHomeVendedor}?${user.user}&&${user.password}#Intent;scheme=https;package=com.android.chrome;end`;

        // Redirige al URL
        window.location.href = url;
            
        }

    };

    foundClient = (response, flag) => {
        const encontre = () => toast.info('¡Usuario encontrado!');
        const noEncontre = () => toast.error('¡Usuario no encontrado, por favor registrarlo !');
        if (flag) {
            if (response.name !== false) {
                console.log("response foundCliente", response);
                encontre();
                this.setState({
                    closeRegistrer: true,
                    loading: false,
                    opacity: 1,
                    identification: response.cedula,
                    nombre: response.nombre,
                    apellido: response.apellido,
                    direccion: response.direccion,
                    email: response.email,
                    celular: response.celular,
                    styleUser: {
                        borderWidth: 2,
                        borderColor: '#07A2BA',
                    },
                    viewUserTicket: true,
                    partner_id: response.partner_id,
                }, () => {
                    // Crear un intervalo para verificar la referencia
                    const scrollInterval = setInterval(() => {
                        if (this.thirdSectionRef.current) {
                            // Si la referencia existe, realizar el scroll
                            this.thirdSectionRef.current.scrollIntoView({ behavior: 'smooth' });
                            // Limpiar el intervalo una vez realizado el scroll
                            clearInterval(scrollInterval);
                        }
                    }, 100); // Verifica cada 100ms
                });

            } else {
                noEncontre();
                this.setState({ isCliente: false, loading: false, opacity: 0.8 });
            }
        } else {
            alert('Ocurrio un error al buscar el cliente, Por favor intentelo de nuevo')
        }
    };

    findClient = () => {
        const errorDigitar = () => toast.error('¡No se le olvide digitar un número de cédula!');
        const rifa = JSON.parse(localStorage.getItem('datosRifa'));
        if (this.state.identification.trim() !== '') {
            console.log('identificacion', this.state.identification);
            this.setState({ loading: true, opacity: 0.4 });
            searchClient(rifa.id, String(this.state.identification), this.foundClient);
        } else {
            errorDigitar();
        }
    };

    handleCompraRealizada = () => {
        try {
            const datosRifa = localStorage.getItem('datosRifa');
            if (datosRifa) {
                const rifa = JSON.parse(datosRifa);

                this.setState({
                    selectedRifa: {
                        id: rifa.id || -1,
                        image_medium: rifa.imagen || null,
                        name: rifa.name || '',
                        precio: rifa.precio || '',
                        fecha_sorteo: rifa.sorteo || '',
                        porcentaje_recaudo: rifa.porcentaje_recaudo || '',
                        resolucion: rifa.resolucion || '',
                        operador_efectivo: rifa.operador_efectivo || '',
                        operador_tarjeta: rifa.operador_tarjeta || '',
                    },
                    compraRealizada: true,
                }, () => {
                    const scrollInterval = setInterval(() => {
                        if (this.secondSectionRef.current) {
                            this.secondSectionRef.current.scrollIntoView({ behavior: 'smooth' });
                            clearInterval(scrollInterval); // Detenemos el intervalo cuando encontramos la referencia
                        }
                    }, 100); // Verifica cada 100 ms
                });
            } else {
                console.warn("No se encontraron datos de rifa en AsyncStorage");
            }
        } catch (error) {
            console.error('Error al obtener los datos de la rifa:', error);
        }
    };


    handleCheckboxChange = () => {
        this.setState((prevState) => ({ isCliente: !prevState.isCliente }));
    };

    render() {
        const rifa = JSON.parse(localStorage.getItem('datosRifa'));

        return (
            <>
                <style>
                    {`
                        .my-auto {
                            margin-top: auto;
                            margin-bottom: auto;
                        } 
                        .reloadsCard {
                            box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.17);
                            border-radius: 1em;
                            margin: 5px;
                        }
                    `}
                </style>

                <div style={{ marginTop: '70px', opacity: this.state.opacity }} ref={this.firstSectionRef}>

                    <ToastContainer />

                    <div style={{ padding: '0px 2% 0px 2%' }} >
                        <CarruselRifas rifas={this.rifas} onCompraRealizada={this.handleCompraRealizada} userLogin={this.state.userLogin}/>
                    </div>

                    {this.state.compraRealizada && !this.state.closeRegistrer && (
                        <div className="cuadro-estilo" ref={this.secondSectionRef}>
                            <label style={{ display: 'block', marginBottom: '8px' }}>
                                <div style={{ border: '1px solid #07A2BA', borderRadius: 13, padding: '8px', display: 'inline-block' }}>
                                    Soy Cliente

                                    <input
                                        type="checkbox"
                                        checked={this.state.isCliente}
                                        onChange={this.handleCheckboxChange}
                                        style={{ marginLeft: '8px'}}
                                    />
                                </div>
                            </label>

                            {this.state.isCliente ? (
                                <>
                                    <Form.Label className={'defaultTitle'}>Cédula</Form.Label>
                                    <Form.Control
                                        value={this.state.identification}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            // Validar que la longitud no sea mayor a 10
                                            if (value.length <= 10) {
                                                this.setState({ identification: value, });
                                            }
                                        }}
                                        type="number"
                                        placeholder="Cédula"
                                        style={{ borderRadius: 13 }}
                                    />
                                    <Button style={{ marginTop: '2%' }} variant="info" onClick={this.findClient}>
                                        Buscar Cliente
                                    </Button>
                                </>
                            ) : (
                                <RegistroCliente
                                    setCliente={(cedula, nombre, apellido, direccion, email, celular) => {
                                        this.setState({
                                            userTicket: cedula + ' - ' + nombre,
                                            styleUser: { borderWidth: 2, borderColor: '#07A2BA' },
                                            identification: cedula, nombre: nombre, apellido: apellido, direccion: direccion, email: email, celular: celular
                                        });
                                        console.log("datos que llegan en setCliente", cedula, nombre, apellido, direccion, email, celular);
                                    }}
                                    id_product={rifa.id}
                                    visible={this.state.visibleRegistro}
                                    setParther={(id) => this.setState({ partner_id: id })}
                                    closeRegistrer={(flag) => {
                                        this.setState({ closeRegistrer: flag }, () => {
                                            if (flag) {
                                                const scrollInterval = setInterval(() => {
                                                    if (this.thirdSectionRef.current) {
                                                        this.thirdSectionRef.current.scrollIntoView({ behavior: 'smooth' });
                                                        // Limpiar el intervalo una vez realizado el scroll
                                                        clearInterval(scrollInterval);
                                                    }
                                                }, 100); // Verifica cada 100ms
                                            }
                                        });

                                    }}
                                />
                            )}
                        </div>
                    )}
                    {this.state.compraRealizada && this.state.closeRegistrer &&
                        <div className="cuadro-estilo">
                            <DatosCliente
                                cliente={{
                                    cedula: this.state.identification,
                                    nombre: this.state.nombre,
                                    apellido: this.state.apellido,
                                    direccion: this.state.direccion,
                                    correo: this.state.email,
                                    celular: this.state.celular,
                                }}
                            />
                        </div>}

                    {this.state.compraRealizada && this.state.closeRegistrer &&
                        <div className="cuadro-estilo" ref={this.thirdSectionRef}>
                            <SeleccionarBoleta
                                datos={{ partner_id: this.state.partner_id, identificacion: this.state.identification, nombre: this.state.nombre, apellido: this.state.apellido, correo: this.state.email, telefono: this.state.celular }}
                                numberTicket={(number) => this.setState({ numberTicket: number}, () => {
                                    const scrollInterval = setInterval(() => {
                                        if (this.fourthSectionRef.current) {
                                            this.fourthSectionRef.current.scrollIntoView({ behavior: 'smooth' });
                                            // Limpiar el intervalo una vez realizado el scroll
                                            clearInterval(scrollInterval);
                                        }
                                    }, 100); // Verifica cada 100ms
                            })}
                            />
                        </div>}

                    {this.state.compraRealizada && this.state.closeRegistrer && this.state.numberTicket &&
                        <div className="cuadro-estilo" ref={this.fourthSectionRef}>
                            <PagosExternosScreen
                                datos={{ product_id: this.state.selectedRifa.id, partner_id: this.state.partner_id, identificacion: this.state.identification, nombre: this.state.nombre, apellido: this.state.apellido, correo: this.state.email, telefono: this.state.celular, numBoleta: this.state.numberTicket, precio: this.state.selectedRifa.precio, nameRifa: this.state.selectedRifa.name, resolucion: this.state.selectedRifa.resolucion, sorteo: this.state.selectedRifa.fecha_sorteo, operador_efectivo: this.state.selectedRifa.operador_efectivo }}
                            />
                        </div>}
                </div>
                {this.state.loading && <LoadingMovilGo />}
            </>
        );
    }
}

export default LandingPage;
