import Odoo from 'odoo-xmlrpc';
import { OdooConfig } from '../utilities/odoo-config';
import { Desencrypt } from '../utilities/cipher';

export const searchClient = async (product_id, cedula, callback) => {

    const args = [[0, { product_id, cedula }]]

    // console.log("Cliente: ", args)

    const config = await OdooConfig();
    const data = await Desencrypt();


    const params = {
        model: "movilgo.webservice",
        method: "buscarCliente",
    }

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });

    client.connect((err, response) => {
        if (err) {
            console.log("Error conect buscando cliente", err);
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, (err, response) => {
                if (err) {
                    console.log("Error servicio buscando cliente", err, response);
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });
        }

    })
}

export const saveClient = async (usuario, callback) => {
    const args = [[0, usuario]]
    const config = await OdooConfig();
    const data = await Desencrypt();
    const params = {
        model: "movilgo.webservice",
        method: "registrarCliente",
    }
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });
    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, (err, response) => {
                if (err) {
                    return false;
                }
                callback(response, true);
            });
        }
    })
}

