import React, { PureComponent } from 'react';
import { Button, Card, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export class SeguroExequial extends PureComponent {

    render() {
        return (
            <Container style={{ marginTop: '5%' }}>
                <Row className="justify-content-md-center">
                    <Card className='reloadsCard' style={{ minWidth: '40%' }}>
                        <Card.Body>
                            <div>
                                <h3>¿Que desea realizar?</h3>
                            </div>
                            <Button
                                style={{ marginLeft: '5%' }}
                                variant={'info'}
                                className="mt-2"
                            >
                                <Link to='/Seguro-excequial/Buscar' style={{ color: 'white' }}>Buscar</Link>
                            </Button>
                            <Button
                                style={{ marginLeft: '10%' }}
                                variant={'info'}
                                className="mt-2"
                            >
                                <Link to='Seguro-excequial/Lista-Seguros' style={{ color: 'white' }}>Comprar</Link>
                            </Button>

                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        )
    }
}
