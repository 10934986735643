import Odoo from 'odoo-xmlrpc';
import { OdooConfig } from '../utilities/odoo-config';
import { Desencrypt } from '../utilities/cipher';

export const searchNumber = async (numero_boleta, product_id, callback) => {
    const config = await OdooConfig();
    const args = [[ 0, { numero_boleta, product_id } ]]
    //console.log("args buscar numero", args);
    const data = await Desencrypt();
    const params = {
        model: "movilgo.webservice",
        method: "buscarBoleta",
    }
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });
  
    //console.log("args de salida buscarBoleta",args)
    client.connect((err, response) => {
        /* console.log("err del conect buscarBoleta",err)
        console.log("responde del conect buscarBoleta",response) */
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, (err, response) => {
               //console.log("err del serviciio buscarBoleta",err)
                //console.log("response del servicio buscarBoleta",response)
                if (err) {
                    callback(err, false);
                    return false;
                }

                callback(response, true);
            });
        }

    })
}

export const searchPayments = async (tipo, valor, product_id, callback) => {
    const config = await OdooConfig();
    const args = [[ 0, { tipo, valor, product_id } ]]
   
    const data = await Desencrypt();
    const params = {
        model: "movilgo.webservice",
        method: "buscarAbonoRifa",
    }
    
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });
    //console.log("vendedor args",args)
    //console.log("vendedor cliente",client)
    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, (err, response) => {
                if (err) {
                    ///callback(err, false);
                    return false;
                }
                callback(response, true);
            });
        }

    })
}

export const getRifaResume = async (transaccion, callback) => {
    const config = await OdooConfig();
    const args = [[ 0, { transaccion } ]]
    const params = {
        model: "movilgo.webservice",
        method: "traerRifa",
    }
    
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: '8079',
        password: '1111'
    });

    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, (err, response) => {
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });
        }

    })
}