
import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { NavLink, Redirect } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import Modal from 'react-modal';
import Logo from '../../assets/logo.png';
import logoCardoso from '../../assets/Cardoso_Logo.png';
import { Button } from 'react-bootstrap'
import { LoginComponent } from '../../screens/login/login';
import { Encrypt, Desencrypt } from '../../utilities/cipher';
import { useToasts } from 'react-toast-notifications'
import { toast } from 'react-toastify';
import { Login } from '../../services/login.service';
import header from '../../assets/header/header.css';
import { idCardoso, idCardosoVendedor, idLandinPage, idMobilgoVendedor, idvendedorqr } from '../../utilities/odoo-config';


const HeaderDefault = (props) => {
    const { init } = props;
    const [viewModalLogin, setViewModalLogin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userLog, setUserLog] = useState(false);
    const [userName, setUserName] = useState('preparent');
    const [redirect, setRedirect] = useState(<></>);
    const [showcardoso, setShowcardoso] = useState(false);
    const [showcajas, setShowcajas] = useState(true)
    const [showMenu, setShowMenu] = useState(true)


    Modal.setAppElement('div')
    const { addToast } = useToasts()
    const initialiceData = async () => {
        const data = await Desencrypt();
        const Idpadre = localStorage.getItem('idPadre');
        if (data) {
            setUserName(data.user);
            setUserLog(true);
            if ((data.id == idCardosoVendedor) || (data.id == idMobilgoVendedor) || (data.id == idvendedorqr)) {
                setShowcajas(false);
            } else {
                setShowcajas(true);
            }
            console.log("Datos de sesion", data)

            if (idLandinPage.includes(data.id.toString())) {
                setShowMenu(false);
            } else {
                setShowMenu(true);
            }

            if ((data.id == idCardosoVendedor) || (Idpadre == idCardoso)) {
                setShowcardoso(true)
            } else {
                setShowcardoso(false)
            }
        } else {
            setUserName('');
        }
    }
    useEffect(() => {
        let url = window.location.search;
        if (url.includes('?')) {
            logueo()
        }

    }, [])

    const decryptText = (encryptedText) => {
        const secretKey = 'mySecretKey12345'; // misma clave utilizada para encriptar
        const bytes = CryptoJS.AES.decrypt(encryptedText, secretKey);
        const decrypted = bytes.toString(CryptoJS.enc.Utf8);
        console.log("decrpted...", decrypted);
        return decrypted;
    };

    const logueo = () => {
        let url = window.location.search;
        let data = {}
        if (url.includes('&&')) {
            const urlPart = url.split("&&")
            const user = JSON.stringify(urlPart[0]).replace('?', '').replace('"', '').replace('"', '');
            const password = urlPart[1]
            data = { user, password }
            console.log("data de la url", data)

        } else {
            const dataLogin = decryptText(url.substring(1))
            const parts = dataLogin.split(',');
            console.log('parts...', parts);
            const username = parts[0].trim();
            const password = parts[1].trim();
            data = { user: username, password: password };
            console.log("data de la url encrypted", data);
        }

        Login(data, (response, flag) => {
            if (flag) {
                const init = false
                login(response, flag, init)
                setRedirect(<Redirect to="/LandingPage/" />)

            } else {
                addToast('Error', {
                    appearance: 'error',
                    autoDismiss: true,
                });
            }
        });

    }

    initialiceData();
    const login = (data, flag, inicializar) => {
        if (flag) {
            Encrypt(data.user);
            setUserName(data.user.user);
            setUserLog(true);
            setViewModalLogin(false);
            addToast('Bienvenido a MóvilGo ' + data.user.user, {
                appearance: 'success',
                autoDismiss: true,
            })
            if (inicializar) {

                setTimeout(() => {
                    init();
                    // window.location.reload();
                }, 10000);
            }
            return
        } else {
            const errorr = JSON.stringify("ErrorSession TypeError: network erro");

            switch (data) {
                case 1:
                    addToast('Usuario y/o contraseña incorrecta.', {
                        appearance: 'error',
                        autoDismiss: true,
                    });

                    break;
                case 2:
                    addToast(errorr, {
                        appearance: 'error',
                        autoDismiss: true,
                    });
                    break;

                case 3:
                    addToast('Debe ingresar el usuario y la contraseña.', {
                        appearance: 'error',
                        autoDismiss: true,
                    });

                    break;
                case 4:
                    addToast('Debe ingresar el usuario.', {
                        appearance: 'error',
                        autoDismiss: true,
                    });

                    break;
                case 5:
                    addToast('Debe ingresar la contraseña.', {
                        appearance: 'error',
                        autoDismiss: true,
                    });
                    break;
                default:
                    break;
            }
        }
        setLoading(!loading)

    }

    const closeSession = () => {
        localStorage.removeItem("Session");
        localStorage.removeItem("Permission");
        setUserLog(false);
        setRedirect(<Redirect to="/" />)
    }
    return (
        <>
            <style>
                {`
                .my-navStyle {                
                    background-color: #FFFFFF ;              
                } 
                .navColor {
                    background-color: #0D606E  ;                
                }
                .styleNavBar {
                    padding: 0px 10vw 0px 5vw;
                    border-bottom: 3px solid #07A2BA;
                }
                `}
            </style>
            {redirect}
            <Navbar collapseOnSelect className='styleNavBar' expand="lg" bg="light" >

                <Navbar.Brand href="/" style={{ width: '65%' }}>
                    <img
                        className="img-responsive"
                        src={Logo}
                        width="25%"
                        alt="MovilGo-Logo"
                    />


                    {showcardoso &&
                        <img
                            className="img-responsive tamaño"
                            src={logoCardoso}
                            width="25%"
                            alt="MovilGo-Logo"
                        />}
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                {showMenu
                    &&
                    <Navbar.Collapse id="responsive-navbar-nav">


                        <Nav className="ml-auto"  >
                            <Nav style={{ fontSize: '25px' }} className="justify-content-end">
                                <NavLink className='nav-link text-info' style={{ textAlign: 'center', backgroundColor: 'transparent' }} to="/">Inicio</NavLink>
                                {userLog && <NavLink className='nav-link text-info' to="/Servicios" style={{ textAlign: 'center' }} >Servicios</NavLink>}
                                {!userLog && userName === '' &&
                                    <Button
                                        style={{ backgroundColor: 'rgba(138,221,45,0)', border: 'none', fontSize: 25 }}
                                        className='nav-link text-info'
                                        onClick={() => setViewModalLogin(true)} >Iniciar Sesión</Button>}
                                {userLog &&
                                    <NavDropdown style={{ textAlign: 'center', backgroundColor: 'transparent', color: '#00A5BA' }} title={userName} >
                                        <NavLink className='nav-link text-info' to="/Servicios">Servicios</NavLink>
                                        {showcajas && <NavLink className='nav-link text-info' to="/Caja">Caja</NavLink>}
                                        {showcajas && <NavLink className='nav-link text-info' to="/CajaFecha">Caja por Fecha</NavLink>}
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item href="/" onClick={() => { closeSession() }}>Cerra Sesión </NavDropdown.Item>
                                    </NavDropdown>}
                            </Nav>
                        </Nav>
                    </Navbar.Collapse>
                }

                {viewModalLogin && <LoginComponent loading={loading} viewModalLogin={(flag) => setViewModalLogin(flag)} onLogin={(resp, flag) => { login(resp, flag, true) }} />}

            </Navbar>

        </>
    );
}


export default HeaderDefault;