
import Odoo from 'odoo-xmlrpc';
import { OdooConfig } from '../utilities/odoo-config';
import { Desencrypt } from '../utilities/cipher';

export const Transaction = async (data, call) => {
    
    const config = await OdooConfig();
    const user = Desencrypt();
    let finish = false;
    const args = [[0, data]];

    console.log("PRODUCT.SERVICE ARGS: ",args )

    const params = {
        model: "movilgo.webservice",
        method: "transaccion",
    };

    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        call(false, "");
        return;
    }, 100000);

    const odoo = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: user.user,
        password: user.password
    });

    odoo.connect((errLogin, responseLogin) => {
        if (errLogin) {
            return;

        }
        odoo.execute_kw(params.model, params.method, args, (err, response) => {
            //console.log("response del porduct " + data.atributes.tipo, response)
            console.log("ERR de proucto err,",err  )
            console.log("response de proucto,response", response )
            finish = true;
            if (err) {
                /* call(err,false); */
                call(false, err);
                return false;
            }

            if (response) {
                if (response.errores && response.errores.id) {

                    call(false, response);
                    return false;
                }
                call(true, response);
                return true;
            }
            return false;

        });
    })
}

