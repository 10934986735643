import React, { PureComponent } from 'react';
//import { Card } from 'react-bootstrap';
import { ListServices } from './components/list-services';
import { Row, Col } from 'react-bootstrap';
import { idCallcenter, idCardoso, idMobilgoVendedor, idPadreRata, idvendedorqr } from '../../utilities/odoo-config';
import { Desencrypt } from '../../utilities/cipher';

class MenuServices extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            listaPaquetes: '',
            routes: []
        }
    }
    
    onClickRoutes = (route) => {
        this.props.history.push(route);
    }

    componentDidMount = () => {
        const permiso = localStorage.getItem('Permission');
        const Idpadre = localStorage.getItem('idPadre');
        const datoSession = Desencrypt();
        let routes = Routes;
        if (permiso === 'cobrador') {
            routes = RoutesCollector;
        } else if (permiso === 'administrador') {
            routes = RoutesAdmin;
        } else if (Idpadre === idCardoso || Idpadre == idPadreRata ) {
            routes = RouteCardoso;
        }
        else if (datoSession.id == idMobilgoVendedor || datoSession.id == idCallcenter || datoSession.id == idvendedorqr) {
            routes = RouteCardoso;
        }

        this.setState({ listaPaquetes: this.props.listaPaquetes, routes })
    }

    render() {
        return (
            <div style={{ padding: '100px 10vw 40px 5vw' }}>

                <h2 className={'defaultTitle'}>Servicios MóvilGo </h2>
                <Row className="justify-content-md-center">
                    <Col>
                        <ListServices
                            services={this.state.routes}
                            onClick={(route) => this.onClickRoutes(route)}
                        />
                    </Col>
                </Row>
            </div>
        );
    };
}

const Routes = [
    {
        image: require('../../assets/services/btn-recargas.png'),
        source: '/Recargas'
    },
    {
        image: require('../../assets/services/btn-paquetes.png'),
        source: '/Paquetes'
    },
    {
        image: require('../../assets/services/btn-rifas.png'),
        source: '/Rifas'
    } ,
    {
        image: require('../../assets/services/btn-pines.png'),
        source: '/Pines'
    },
    {
        image: require('../../assets/services/btn-deportes.png'),
        source: '/Recargas-Deportivas'
    },
    {
        image: require('../../assets/services/btn-exequial.png'),
        source: '/Seguro-excequial'
    },
    /*
    {
        image: require('../../assets/services/btn-soat.png'),
        source: '/Soat'
    },
    {
        image: require('../../assets/services/botonbingo.png'),
        source: '/Super-Bingo'
    }, */

 /*  Este fragmento permite el acceso a "Certificados" que se encuentra 
    en "route.js", el cual su vez redirecciona a la vista. */
    /* {
        image: require('../../assets/services/btn-certificado.png'),//require('../../assets/services/btn-certificados.png'),
        source: '/Certificados'
    },
     {
    image: require('../../assets/services/btn-prestamos.png'),
    source: '/Prestamos/Busca'
    },*/ 
    
    /* { image: require('../../assets/services/btn-prestamos.png'),
    source: '/Mitecnova/' }, */
   
    
    
]

const RoutesCollector = [
    {
        image: require('../../assets/services/botoncobrador.png'),
        source: '/Recaudo'
    },
]

const RouteCardoso =[
    {
        image: require('../../assets/services/btn-rifas.png'),
        source: '/Rifas'
    },
]

const RoutesAdmin = [
    {
        image: require('../../assets/services/btn-recargas.png'),
        source: '/Recargas'
    },
    {
        image: require('../../assets/services/btn-paquetes.png'),
        source: '/Paquetes'
    },
    {
        image: require('../../assets/services/btn-rifas.png'),
        source: '/Rifas'
    },
    {
        image: require('../../assets/services/btn-pines.png'),
        source: '/Pines'
    },
    {
        image: require('../../assets/services/btn-deportes.png'),
        source: '/Recargas-Deportivas'
    },
    {
        image: require('../../assets/services/btn-soat.png'),
        source: '/Soat'
    },
    {
        image: require('../../assets/services/botoncobrador.png'),
        source: '/Recaudo'
    },    
    /* {
        image: require('../../assets/services/btn-deportes.png'),//require('../../assets/services/btn-certificados.png'),
        source: '/Certificados'
    },
    */
    {
        image: require('../../assets/services/btn-prestamos.png'),
        source: '/Prestamos/Busca'
    }, 
    /* { image: require('../../assets/services/btn-prestamos.png'),
        source: '/Mitecnova' }, */
]

export default MenuServices;