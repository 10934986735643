import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FormatMoney } from '../../utilities/formats';

export const ListPrices = (props) => {    
    const { prices = [], onClick,clean, limpiar } = props;
        
    const [clicked, setClickedBtn] = useState(false);
    const [indexBtn, setIndexBtn] = useState(-1);    

    const handleClick = (index) => {
        clean(true);
        setClickedBtn(true);
        setIndexBtn(index);
    }

    useEffect(() => {
        setClickedBtn(limpiar)
    }, [limpiar]);

    return (
        <div>
            {prices.map((price, i) => {
                let btn_class;
                if (indexBtn !== -1) {
                    if (indexBtn === i) {
                        btn_class = clicked ? "#bedb02" : ""
                    }
                }
                return (
                    <Button index={i} className='m-1' variant='info' 
                    style={{ width: 100, backgroundColor: btn_class }} 
                    key={i} onClick={() => { onClick(price, i); handleClick(i) }}>  
                    {FormatMoney(price)}  </Button>
                )
            })}
        </div>
    )

}

export const ListNumberTicket = (props) => {
    const { numbers = [], selectTicket, active } = props;
    const [clicked, setClickedBtn] = useState(false);
    const [indexBtn, setIndexBtn] = useState(-1);
    const handleClick = (index) => {
      setClickedBtn(true);
      setIndexBtn(index);
    };
    console.log("ListNumberTicket number", numbers);
  
    // Filtrar los elementos undefined del array
    const validNumbers = numbers.filter((number) => number !== undefined);
  
    return (
      <div>
        {validNumbers.map((number, i) => {
          let btn_class = "";
          if (indexBtn !== -1 && indexBtn === i && clicked) {
            btn_class = "#bedb02";
          }
  
          return (
            <Button
              index={i}
              className='m-1'
              variant='info'
              style={{ width: 100, backgroundColor: btn_class }}
              key={i}
              onClick={() => {
                selectTicket(number.numero_boleta);
                active(false);
                handleClick(i);
              }}
            >
              {number.numero_boleta}
            </Button>
          );
        })}
      </div>
    );
  };

  export const ListNumberTicketLandinPage = (props) => {
    const { numbers = [], selectTicket, active, selectedTicket } = props;
    const [clicked, setClickedBtn] = useState(false);
    const [indexBtn, setIndexBtn] = useState(-1);
    const handleClick = (index) => {
      setClickedBtn(true);
      setIndexBtn(index);
    };
  
    useEffect(() => {
      if (!selectedTicket) {
        setClickedBtn(false);
      }
    }, [selectedTicket]);
  
    const renderSelectedTicket = () => (
      <Button
        className='m-1'
        variant='info'
        style={{
          width: 100,
          backgroundColor: '#bedb02',
        }}
        onClick={() => {
          active(false);
        }}
      >
        {selectedTicket}
      </Button>
    );
  
    const renderAvailableTickets = () => (
      <div>
        {numbers
          .filter((number) => number !== undefined) // Filtra elementos undefined
          .map((number, i) => (
            <React.Fragment key={i}>
              <Button
                index={i}
                className='m-1'
                variant='info'
                style={{
                  width: 100,
                  backgroundColor: indexBtn !== -1 && indexBtn === i && clicked ? '#bedb02' : '',
                }}
                onClick={() => {
                  selectTicket(number.numero_boleta);
                  active(false);
                  handleClick(i);
                }}
              >
                {number.numero_boleta}
              </Button>
              {/* Add a line break after every third button */}
              {(i + 1) % 3 === 0 && i !== numbers.length - 1 && <br />}
            </React.Fragment>
          ))}
      </div>
    );
    
  
    return selectedTicket ? renderSelectedTicket() : renderAvailableTickets();
  };
 
  
  
  
  
  
  
