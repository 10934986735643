
import jsPDF from 'jspdf'
export const jsPdfGenerator = (datos) => {
  var doc = new jsPDF('p', 'pt');
  doc.setFontSize(20);
  doc.text(65, 35, 'MóvilGo S.A.S');
  doc.setFontSize(13);
  doc.text(13, 50, 'Buenas e inteligentes ideas');
  doc.setFontSize(13);
  doc.text(13, 65, 'NIT: 901.330.856-1');
  doc.setFontSize(13);
  doc.text(13, 80, 'Carrera 23#62-39(AV.Santander)');
  doc.setFontSize(13);
  doc.text(13, 95, 'ED.Capitalia OF-404B');
  doc.setFontSize(13);
  doc.text(13, 110, '3216435009 - 3168194210');
  let cont = 135;
  let factura=''
  datos.forEach(element => {
    if(factura===''){
      factura=element
    }
    doc.text(13, cont, element);
    cont = cont + 20
  });
  var string = doc.output('datauristring');

  var iframe = "<iframe width='100%' height='100%' src='" + string + "'></iframe>"

  var x = window.open();
  x.document.open();
  x.document.write(iframe);
  x.document.close();

  doc.save(factura+'.pdf');
}

