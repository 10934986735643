import React, { PureComponent } from 'react';
import { Row, Col, Card, InputGroup, FormControl, Button, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { ListPrices } from './list';
import NumberFormat from 'react-number-format';
import { ModalRegistro } from './modal-registro';
import { ModalBoletas } from './modal-boletas';
import { searchNumber } from '../../../services/rifa.service';
import { searchClient } from '../../../services/cliente.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Desencrypt } from '../../../utilities/cipher';
import { Transaction } from '../../../services/product.service';
import { FormatMoney } from '../../../utilities/formats';
import { ManagmentErrors } from '../../../shared/components/managmentErrors';
import { LoadingMovilGo } from '../../../shared/components/loadingMovilGo';
import { ModalNotificationTransaction } from '../../../shared/components/modalNotificationTransaction';
import { jsPdfGenerator } from '../../../shared/components/pdfTransaction';
import compra from '../../../assets/comprar/comprar.css';
import { Url_Home, Url_pago, idCallcenter, idCardoso, idCardosoVendedor, idMobilgoVendedor, idPadreRata, idvendedorqr } from '../../../utilities/odoo-config';


class Compras extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            selectedPrice: '',
            boleta: '',
            visibleRegistro: false,
            visibleBoletas: false,
            creditoRifa: '',
            buyMessage: <></>,
            numberTicket: '',
            identification: '',
            userPhone: '',
            userTicket: '',
            message: <></>,
            styleTicket: {},
            styleUser: {},
            viewTicket: false,
            viewUserTicket: false,
            partner_id: -1,
            prices: [],
            fullNameUser:'',
            loading: false,
            opacity: 1,
            modalNotification: false,
            showRifa: <></>,
            luckyNumber: false,
            rifa: JSON.parse(localStorage.getItem('datosRifa')),
            idProduct: -1,
            showcomprar: true,
        };
        this.numbersTicket = [];
        this.allNumbersTicket = [];
        this.numSuerte = 0;
    }
    componentDidMount = () => {
        const idPadre = localStorage.getItem('idPadre');
        const datoSession = Desencrypt();
        //console.log('rifa en comprar',this.state.rifa);
        if ((datoSession.id == idCardosoVendedor) || (datoSession.id == idMobilgoVendedor) || (datoSession.id == idCallcenter) || (datoSession.id == idvendedorqr)) {
            this.setState({ showcomprar: false })
        } else {
            this.setState({ showcomprar: true })
        }
        this.price();

    }

    calcularAbono = (price, valorRifa) => {

        if (price === valorRifa) {
            this.setState({
                buyMessage: (
                    <h5> Realizo el pago Completo </h5>
                )
            }
            );
        } else {
            this.setState({
                buyMessage: (
                    <h5> Abonó: {FormatMoney(price)}</h5>
                )
            }
            );
        }
    }
    selectTicket = (number) => {
        this.setState({ numberTicket: number })
    }
    selectPrice = (price) => {
        const datoSession = Desencrypt();
        const rifa = JSON.parse(localStorage.getItem('datosRifa'));

        this.calcularAbono(price, rifa.precio)

        if (typeof (price) === 'string' && price.toLowerCase() === 'otro valor') {
            this.setState({ visible: true, selectedPrice: '' });

        } else if (typeof (price) === 'string' && price.toLowerCase() === 'reservar') {
            console.log("el Price", price)
            this.setState({ selectedPrice: '0', visible: false });
        }
        else {
            this.setState({ selectedPrice: price, visible: false });

        }

    }
    showModal = (flag) => {
        this.setState({ visibleRegistro: flag, boleta: '' });
        this.setState({ visibleBoletas: flag });
    }
    chooseTicket = (flag) => {
        this.setState({ visibleBoletas: flag, styleTicket: { borderWidth: 2, borderColor: '#07A2BA' }, viewTicket: true });

    }
    foundNumber = (response, flag) => {


        const encontrada = () => toast.info("¡Se encontró la boleta solicitada!");
        const boletasRelacionadas = () => toast.warning("¡No se encontró la boleta solicitada, pero hay algunas con relación!");
        const boletasNumSuerte = () => toast.info("Tu número de la suerte es el " + this.numSuerte + ", éstas son tus sugerencias");

        if (flag) {
            let esta = false;
            //this.numbersTicket = response
            let newNumber = []
            this.allNumbersTicket = response.disponibles
            //console.log("response de los tikes",response.disponibles[0].product_id)
            this.setState({ idProduct: response.disponibles[0].product_id })

            if (this.state.luckyNumber) {
                this.numbersTicket.push(response.disponibles[0])
            } else {
                for (let k = 0; k < 6; k = k + 1) {
                    newNumber.push(response.disponibles[k])
                }
                this.numbersTicket = newNumber

            }
            //console.log("this.numbersTicket de los tikes",this.numbersTicket)
            if (response?.errores) {
                alert("Se produjo un error al consultar la boleta")
                return

            } else if (response.disponibles.length > 0) {
                if (this.state.luckyNumber) {
                    this.setState({ visibleBoletas: true, loading: false, opacity: 1 })
                } else {
                    for (let index = 0; index < response.disponibles.length; index++) {
                        //console.log("for ",this.state.numberTicket,response.disponibles[index])
                        if (this.state.numberTicket == response.disponibles[index].numero_boleta) {
                            esta = true;
                        }
                    }
                    if (esta) {
                        encontrada();
                        this.setState({ styleTicket: { borderWidth: 2, borderColor: '#07A2BA' }, viewTicket: true, loading: false, opacity: 1 });
                    } else {
                        //noEncontre();
                        //this.setState({ loading: false, opacity: 1 })
                        this.setState({ visibleBoletas: true, loading: false, opacity: 1 })
                    }

                }

                /* 
                                if (response.disponibles.length === 1) {
                                    encontrada();
                                    this.setState({ styleTicket: { borderWidth: 2, borderColor: '#07A2BA' }, viewTicket: true, loading: false, opacity: 1 })
                                } else {
                                    const size = response.disponibles[0].length
                                    const enviSize = this.state.numberTicket.length
                                    if (size === enviSize) {
                                        for (let index = 0; index < this.numbersTicket.length; index++) {
                                            console.log("for ",this.state.numberTicket,this.numbersTicket[index])
                                            if (this.state.numberTicket == this.numbersTicket[index].numero_boleta) {
                                                esta = true;
                                            }
                                        }
                                        if (esta) {
                                            encontrada();
                                            this.setState({ styleTicket: { borderWidth: 2, borderColor: '#07A2BA' }, viewTicket: true, loading: false, opacity: 1 });
                                        } else {
                                            noEncontre();
                                            this.setState({ loading: false, opacity: 1 })
                                        }
                                    }
                                    else {
                                        if (this.numSuerte > 0) {
                                            boletasNumSuerte();
                                            this.setState({ visibleBoletas: true, loading: false, opacity: 1 })
                                        } else {
                                            boletasRelacionadas();
                                            this.setState({ visibleBoletas: true, loading: false, opacity: 1 })
                                        }
                                    }
                                } */
            } /* else {
                noEncontre();
                this.setState({ loading: false, opacity: 1 })
            } */
        }
    }

    searchTicket = () => {
        const errorDigitar = () => toast.error("¡No se le olvide digitar un número de boleta!");
        const rifa = JSON.parse(localStorage.getItem('datosRifa'));
        this.numbersTicket = [];
        if (this.numSuerte !== 0) {
            this.setState({ loading: true, opacity: 0.4 });
            searchNumber(this.numSuerte, rifa.id, this.foundNumber);
        } else if (this.state.numberTicket.trim() !== "") {
            this.setState({ loading: true, opacity: 0.4 });
            searchNumber(this.state.numberTicket, rifa.id, this.foundNumber);
        } else {
            errorDigitar();
        }
    }
    numeroDeLaSuerte = (flag) => {
        this.setState({ luckyNumber: flag })
        const min = 1;
        const max = 99;
        const numeroAleatorio = Math.floor(Math.random() * (max - min)) + min;
        this.numSuerte = numeroAleatorio;
        this.setState({ numberTicket: '' })
        this.searchTicket();
    }

    foundClient = (response, flag) => {
        const encontre = () => toast.info("¡Usuario encontrado!");
        const noEncontre = () => toast.error("¡Usuario no encontrado, por favor registrarlo !");
        if (flag) {
            console.log('Cliente encontrado', response);
            if (response.name !== false) {
                encontre();
                this.setState({
                    loading: false, opacity: 1,
                    userTicket: response.name,
                    styleUser: {
                        borderWidth: 2, borderColor: '#07A2BA'
                    }, 
                    viewUserTicket: true, 
                    partner_id: response.partner_id,
                    userPhone:response.celular,
                    userEmail:response.email,
                    fullNameUser: (response.nombre.trim() + " " + response.apellido.trim())
                });
            } else {
                noEncontre();
                this.setState({ visibleRegistro: true, loading: false, opacity: .8 })
            }
        }
    }

    findClient = () => {
        const errorDigitar = () => toast.error("¡No se le olvide digitar un número de cédula!");
        const rifa = JSON.parse(localStorage.getItem('datosRifa'));
        if (this.state.identification.trim() !== "") {
            this.setState({ loading: true, opacity: 0.4 });
            searchClient(rifa.id, this.state.identification, this.foundClient);
        } else {
            errorDigitar();
        }
    }
    handleChange = (event) => {
        this.numSuerte = 0;
        this.setState({ numberTicket: event.target.value.replace(/\D/, ''), styleTicket: { borderWidth: 1 }, viewTicket: false });
    }

    handleChangeUser = (event) => {
        this.setState({ identification: event.target.value.replace(/\D/, ''), styleUser: { borderWidth: 1 }, userTicket: '' })
    }

    navigateNext = (flag, response) => {
        console.log("response navigate", flag, response)
        if (flag) {
            const rifa = JSON.parse(localStorage.getItem('datosRifa'));
            //console.log("esto es el rifa de la compra de rifa",rifa)
            let data = []
            data = [
                "Factura No: " + response.valida.id,
                "Fecha:" + response.valida.fecha,
                "No.Aprobacion:" + response.valida.numero_aprobacion,
                "Producto: " + rifa.name,
                "Resolucion: " + rifa.resolucion,
                "Fecha del sorteo: " + rifa.sorteo,
                "Número de boleta: " + this.state.numberTicket,
                "Cliente: " + this.state.userTicket,
                "Valor: " + FormatMoney(this.state.selectedPrice)
            ]
            if (rifa.precio > this.state.selectedPrice) {
                data = [
                    "Factura No: " + response.valida.id,
                    "Fecha:" + response.valida.fecha,
                    "No.Aprobacion:" + response.valida.numero_aprobacion,
                    "Producto: Abono " + rifa.name,
                    "Resolucion: " + rifa.resolucion,
                    "Fecha del sorteo: " + rifa.sorteo,
                    "Número de boleta: " + this.state.numberTicket,
                    "Cliente: " + this.state.userTicket,
                    "Valor total: " + FormatMoney(rifa.precio),
                    "Valor abonado: " + FormatMoney(parseInt(this.state.selectedPrice)),
                    "Valor por pagar: " + FormatMoney(rifa.precio - this.state.selectedPrice),
                ]
            }

            //console.log("esto es abono compra", data)
            jsPdfGenerator(data);
            this.props.history.push('/Servicios')
            const success = () => toast.success("Boleta comprada correctamente");
            success();
            this.setState({ modalNotification: true, loading: false, opacity: 1, userTicket: '', viewTicket: false, selectedPrice: '', numberTicket: '', styleTicket: { borderWidth: 1 }, styleUser: { borderWidth: 1 }, identification: '' })

        } else {
            const errorMessage = ManagmentErrors(response.errores.id)
            console.log("mensaje de error", errorMessage);
            const error = () => toast.error(errorMessage);
            error();
            this.setState({ loading: false, opacity: 1, })
        }
    }

    payTicket = () => {
        //const rifa = JSON.parse(localStorage.getItem('datosRifa'));
        const user = Desencrypt();
        try {
            this.setState({ loading: true, opacity: 0.4 })
            let idProduct = ''
            for (const element of this.numbersTicket) {
                if (parseInt(this.state.numberTicket) == parseInt(element.numero_boleta)) {
                    idProduct = element.product_id
                    break;
                }
                console.log("elemento del pay ticket", element); // 👉️ bobby
            }
            const product = {
                product_id: idProduct,
                atributes: {
                    numeroBoleta: "" + this.state.numberTicket,
                    valorPagar: parseInt(this.state.selectedPrice),
                    partner_id: this.state.partner_id,
                    vendedor_externo_id: user.id,
                    nombre_cliente: this.state.userTicket
                }
            }
            console.log("product de la rifa", product)
            localStorage.setItem("NumBolet", this.state.numberTicket);
            Transaction(product, this.navigateNext)
        } catch (error) {

        }
    }

    price = () => {
        const rifa = JSON.parse(localStorage.getItem('datosRifa'));
        const datoSession = Desencrypt();
        let prices = []
        const idPadre = localStorage.getItem('idPadre');
        if (rifa.porcentaje_recaudo === 100 || datoSession.id == idCardosoVendedor || datoSession.id == idMobilgoVendedor) {
            prices = [rifa.precio]
        } else if (datoSession.id == idCallcenter) {
            prices = [
                rifa.precio,
                "Reservar"
            ];
        } else if (idPadre == idPadreRata) {
            //console.log("precio porcentaje: ", (rifa.precio * rifa.porcentaje_recaudo / 100))
            if ((rifa.precio * rifa.porcentaje_recaudo / 100) > 0) {
                prices = [
                    rifa.precio * rifa.porcentaje_recaudo / 100,
                    rifa.precio / 2,
                    rifa.precio,
                    "Otro valor",
                    "Reservar"
                ];
            } else {
                prices = [
                    "Reservar",
                    rifa.precio / 2,
                    rifa.precio,
                    "Otro valor",
                ];
            }
        }
        else {
            if ((rifa.precio * rifa.porcentaje_recaudo / 100) > 0) {
                prices = [
                    rifa.precio * rifa.porcentaje_recaudo / 100,
                    rifa.precio / 2,
                    rifa.precio,
                    "Otro valor"
                ];
            } else {
                prices = [
                    rifa.precio / 2,
                    rifa.precio,
                    "Otro valor",
                ];
            }
        }

        this.setState({ prices })
    }


    generatedLink = async () => {
        const idPadre = localStorage.getItem('idPadre');
        const session = await Desencrypt();
        console.log("id del vendedor", session.id)
        console.log("rifa", this.state.rifa)
        const urlPago = `${Url_pago}PagosExternos/?${this.state.idProduct}&&${session.id}&&${this.state.numberTicket}&&${this.state.selectedPrice}&&${this.state.partner_id}&&${this.state.userTicket.trim()}&&${this.state.rifa.name.trim()}&&${this.state.rifa.resolucion}&&${this.state.rifa.sorteo}&&${this.state.rifa.precio}&&${this.state.identification}&&${this.state.userPhone}&&${this.state.userEmail}&&${this.state.rifa.operador_efectivo}&&${this.state.fullNameUser}`
        console.log("jejeje envio", urlPago.replace(/\s+/g, ''))


        if (('share' in navigator) && (session.id != idCardosoVendedor) && (session.id != idMobilgoVendedor) && (session.id != idvendedorqr) ) {

            navigator.share({
                title: 'Compartir link',
                text: 'Link de pago Rifa MovilGo: ' + this.state.rifa.name,
                url: urlPago.replace(/\s+/g, ''),
            })
                .then(() => {
                    alert('Se compartio el link')
                    window.location.href = Url_Home
                })
                .catch(() => {
                    alert('no se pudo compartir')
                })

        } else {
            window.open(urlPago.replace(/\s+/g, ''), '_blank')
            window.location.href = Url_Home
        }

    }


    render() {
        const rifa = JSON.parse(localStorage.getItem('datosRifa'));
        const session = Desencrypt();
        const idPadre = localStorage.getItem('idPadre');

        return (
            <>
                {this.state.showRifa}
                <h2 className={'defaultTitle'}>Compras</h2>
                <div style={{ marginTop: '70px', opacity: this.state.opacity }}>
                    <ToastContainer autoClose={2000} />
                    <Row className="justify-content-md-center" >
                        <Col md={1} xs={1} sm={1} lg={1}>

                        </Col>
                        <Col md={5} xs={5} sm={5} lg={5}>
                            <Image className='carro' src={'data:image/jpeg;base64,' + rifa.imagen} style={{ maxHeight: '90%', maxWidth: '90%' }} />
                        </Col>
                        <Col md={5} xs={5} sm={5} lg={5}>
                            <Row className="justify-content-md-center tarjeta" >
                                <Card>
                                    <Card.Body>
                                        <Col xs="auto">
                                            <div>
                                                <InputGroup className="mb-2">

                                                    <FormControl
                                                        style={this.state.styleTicket}
                                                        type="number"
                                                        placeholder="Número de boleta"
                                                        arial-label="Número de la boleta"
                                                        value={this.state.numberTicket}
                                                        onChange={(event) => { this.handleChange(event) }}
                                                    />
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text onClick={() => this.searchTicket()}>
                                                            <FontAwesomeIcon className="mr-2 align-self-center" icon={faSearch} />
                                                        </InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                </InputGroup>
                                                <br />
                                                <InputGroup className="mb-2">


                                                    <FormControl
                                                        type="number"
                                                        style={this.state.styleUser}
                                                        placeholder="Cédula"
                                                        arial-label="Número de identificación"
                                                        value={this.state.identification}
                                                        onChange={(event) => { this.handleChangeUser(event) }}
                                                    />
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text onClick={() => this.findClient()}>
                                                            <FontAwesomeIcon className="mr-2 align-self-center" icon={faSearch} />
                                                        </InputGroup.Text>
                                                    </InputGroup.Prepend>

                                                </InputGroup>
                                                <div>
                                                    <p>
                                                        {rifa.name} <br />
                                                        {rifa.sorteo} <br />
                                                        {rifa.precio}<br />
                                                        Boleta: {this.state.viewTicket && this.state.numberTicket}<br />
                                                        Cliente:{this.state.userTicket}
                                                    </p>

                                                </div>
                                            </div>
                                        </Col>
                                    </Card.Body>
                                </Card>
                            </Row>
                            <Row className="justify-content-md-center precio">

                                <div style={{ width: '25rem', marginTop: '5px' }} >
                                    <ListPrices onClick={(i) => this.selectPrice(i)} prices={this.state.prices} /><br />
                                    {this.state.visible &&

                                        <NumberFormat className="form-control mt-1"
                                            thousandSeparator={true}
                                            prefix='$ '
                                            placeholder='$ Montos de Mil'
                                            maxLength={9}
                                            value={this.state.selectedPrice}
                                            onChange={(event) => {
                                                this.setState({ selectedPrice: parseInt(event.target.value.replace("$", "").replace(",", "").replace(",", "")) })
                                                this.calcularAbono(event.target.value, rifa.precio)
                                            }}
                                        />
                                    }
                                </div>

                            </Row>
                            <Row className="justify-content-md-center" style={{ marginTop: '5px' }}>
                                <div className='defaultTitle'>
                                    {this.state.selectedPrice !== 0 ? this.state.buyMessage : ''}
                                </div>
                            </Row>
                            <Row className="justify-content-md-center ">
                                {this.state.userTicket && this.state.viewTicket && this.state.selectedPrice && this.state.showcomprar && this.state.selectedPrice !== '0' &&
                                    <Button onClick={() => this.payTicket()} variant={'info'}>Comprar</Button>}
                                {this.state.userTicket && this.state.viewTicket && this.state.selectedPrice && this.state.selectedPrice !== '0' &&
                                    <Button
                                        onClick={() => this.generatedLink()} variant={'info'} style={{ marginLeft: '10px' }}> Generar pago </Button>}
                                {this.state.userTicket && this.state.viewTicket && this.state.selectedPrice === '0' && (idCallcenter === session.id || idPadreRata === idPadre) && (
                                    <Button onClick={() => this.payTicket()} variant={'info'} style={{ marginLeft: '10px' }}>
                                        Reservar Boleta
                                    </Button>
                                )}
                            </Row>
                            <Row className="justify-content-md-center suerte">
                                <Col md={4} xs={4} sm={4} lg={4}>
                                    <Button className='suerte2' onClick={() => this.numeroDeLaSuerte(true)} variant={'info'} style={{ marginTop: '10px' }}>Número de la suerte</Button>
                                </Col>
                                <Col md={4} xs={4} sm={4} lg={4}>
                                    <Button className='suerte3' onClick={() => this.numeroDeLaSuerte(false)} variant={'info'} style={{ marginTop: '10px' }}>Números para jugar</Button>
                                </Col>
                            </Row>


                        </Col>
                        <Col md={1} xs={1} sm={1} lg={1}>

                        </Col>
                    </Row>

                </div>
                <ModalRegistro cedula={this.state.identification} setCliente={(cedula, nombre) => { this.setState({ userTicket: cedula + " - " + nombre, styleUser: { borderWidth: 2, borderColor: '#07A2BA' } }) }} id_product={rifa.id} visible={this.state.visibleRegistro} setParther={(id) => this.setState({ partner_id: id })} closeModal={(flag) => { this.showModal(flag); this.setState({ opacity: 1 }) }} />
                {this.numbersTicket.length > 0 && <ModalBoletas selectTicket={(number) => this.selectTicket(number)} luckyNumber={this.state.luckyNumber} chooseTicket={(flag) => this.chooseTicket(flag)} numbersTicket={this.numbersTicket} visible={this.state.visibleBoletas} closeModal={(flag) => this.showModal(flag)} />}
                {this.state.loading && <LoadingMovilGo />}
                {this.state.modalNotification && <ModalNotificationTransaction closeModal={(flag) => this.setState({ modalNotification: flag })} type={"Rifa"} message={"Rifa comprada exitosamente"} />}
            </>
        )
    }

}

export default Compras;