import React, { useEffect, useState } from 'react';
import { Card, Button, Row, Container, Col } from 'react-bootstrap';
import { FormatMoney } from '../../../utilities/formats';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { HomeWrapper } from '../../../shared/components/style'
import "react-multi-carousel/lib/styles.css";
import { Link } from 'react-router-dom';
import { idCardoso, idCardosoVendedor, idMobilgoVendedor } from '../../../utilities/odoo-config';
import { Desencrypt } from '../../../utilities/cipher';

export const CarruselRifas = (props) => {
    const { rifas = [] } = props;
    const [cards, setCards] = useState([])
    
    console.log('rifas', rifas);

    const capturarRifa = async (id, name, sorteo, precio, imagen, porcentaje_recaudo, resolucion, operador_efectivo, operador_tarjeta) => {
        localStorage.setItem('datosRifa', JSON.stringify({ id, name, sorteo, precio, imagen, porcentaje_recaudo, resolucion, operador_efectivo, operador_tarjeta }));
    }


    const size = useWindowSize();
    const [numberCards, setNumberCards] = useState(2);
    function useWindowSize() {

        const [windowSize, setWindowSize] = useState({ width: undefined, height: undefined, });

        useEffect(() => {
            function handleResize() {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }
            window.addEventListener("resize", handleResize);
            handleResize();

            return () => window.removeEventListener("resize", handleResize);
        }, []);
        return windowSize;
    }

    useEffect(() => {

        if (size.width <= 768 && size.height <= 1024) {
            setNumberCards(1)
        }
        else if (size.width >= 768 && size.height >= 800) {
            setNumberCards(2)
        }

    }, [size]);


    let settings = {
        arrows: true,
        dots: false,
        accesibility: true,
        infinite: false,
        speed: 300,
        slidesToShow: numberCards,
        slidesToScroll: 1,
        adaptiveHeight: false
    };

    useEffect(() => {
        let aux = [];
        rifas.forEach(element => {
            // console.log("element del carrusel rifa",element)
            if(element.numero_resolucion_rifa!=false){
                const datoSession = Desencrypt();
                var showabono = new Boolean(true)
                if ((datoSession.id == idCardosoVendedor)||(datoSession.id == idMobilgoVendedor)){ 
                    showabono = false
                }else {
                    showabono = true
                }
                aux.push(
                    <div key={element.id} className="col-xs-12" style={{ padding: '5' }}>
                        <Card className="text-center" border="info" style={{ margin: 'auto', minHeight: '29rem' }}>
                            <Card.Header>{element.name}</Card.Header>
                            <Card.Body>
                                <Card.Img src={'data:image/jpeg;base64,' + element.image_medium} style={{ maxHeight: '280px' }} alt="Card image" />
                            </Card.Body>
                            <Card.Footer className="text-muted">
                                <Row>
                                    <Col>
                                        {element.porcentaje_recaudo < 100&&showabono&&
                                            <Button variant="info" onClick={() => capturarRifa(element.id, element.name, element.fecha_sorteo, element.precio, element.image_medium, element.porcentaje_recaudo, element.numero_resolucion_rifa, element.operador_efectivo, element.operador_tarjeta)}>
                                                <Link to='/Rifas/Abonos' style={{ color: 'white' }}>Abonar</Link>
                                            </Button>}
                                    </Col>
                                    <Col>
                                        <p>
                                            {element.fecha_sorteo}
                                            <br />
                                            {FormatMoney(element.precio)}
                                        </p>
                                    </Col>
                                    <Col>
                                        <Button variant="info" onClick={() => capturarRifa(element.id, element.name, element.fecha_sorteo, element.precio, element.image_medium, element.porcentaje_recaudo, element.numero_resolucion_rifa, element.operador_efectivo, element.operador_tarjeta)}>
                                            <Link to="/Rifas/Compras" style={{ color: 'white' }}> Comprar</Link>
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Footer>
                        </Card>
                    </div>
                ); 
            }
            
        })
        setCards(aux)
    }, []);

    return (

        <Container>
            <HomeWrapper>
                <div className="clearfix mt-1 mb-2">
                </div>
                <Col>

                </Col>
                <Slider {...settings} >

                    {cards}
                </Slider>
            </HomeWrapper>
        </Container>

    )
}