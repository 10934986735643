import React, { useState } from 'react';
import { Carousel, Modal, Button } from 'react-bootstrap';
import { idVendedorCardoso24 } from '../../../utilities/odoo-config';

const CarruselRifas = ({ rifas = [], onCompraRealizada, userLogin }) => {
    console.log('user login...', userLogin);
    
    // Organizar el array para que la rifa en la posición 1 esté en primer lugar
    let organizedRifas = [...rifas];
    if (organizedRifas.length > 1) {
        const secondRifa = organizedRifas.splice(1, 1)[0];  // Extraer la rifa en la posición [1]
        organizedRifas.unshift(secondRifa);  // Insertarla al principio
    }

    // Condición para mostrar solo la primera posición si idVendedorCardoso24 es logueado
    if (idVendedorCardoso24 == userLogin.id) {        
        organizedRifas = [organizedRifas[0]]; // Mostrar solo la rifa en la posición 0
    }

    console.log("rifassss", organizedRifas);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const getImageSrc = (rifa) => {
        if (rifa.image_medium && typeof rifa.image_medium === 'string') {
            return `data:image/jpeg;base64,${rifa.image_medium}`;
        }
        return '';
    };

    const handleSelect = (selectedIndex) => {
        setCurrentIndex(selectedIndex);
    };

    const handleImageClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const carouselItemStyle = {
        height: '20rem',
        objectFit: 'cover',
        borderRadius: '0.5rem',
        cursor: 'pointer',
    };

    const thumbnailStyle = {
        width: '5rem',
        height: '5rem',
        objectFit: 'cover',
        cursor: 'pointer',
        transition: 'transform 0.3s ease-in-out, border 0.3s ease-in-out',
        margin: '0 0.5rem',
        borderRadius: '0.5rem',
    };

    const thumbnailContainerStyle = {
        overflowX: 'auto',
        display: 'flex',
        justifyContent: 'center',
        padding: '1rem 0',
    };

    const capturarRifa = async (id, name, sorteo, precio, imagen, porcentaje_recaudo, resolucion, operador_efectivo, operador_tarjeta) => {
        localStorage.setItem('datosRifa', JSON.stringify({ id, name, sorteo, precio, imagen, porcentaje_recaudo, resolucion, operador_efectivo, operador_tarjeta }));
        onCompraRealizada();
    };

    return (
        <div className="container mt-4">
            <h3 className="text-center mb-3">{organizedRifas[currentIndex]?.name}</h3>

            <Carousel
                activeIndex={currentIndex}
                onSelect={handleSelect}
                interval={null}
                prevIcon={<span className="carousel-arrow carousel-arrow-prev">&#8249;</span>}
                nextIcon={<span className="carousel-arrow carousel-arrow-next">&#8250;</span>}
            >
                {organizedRifas.map((rifa, index) => (
                    <Carousel.Item key={index}>
                        <img
                            src={getImageSrc(rifa)}
                            alt={`Rifa ${rifa.name}`}
                            className="d-block w-100"
                            style={carouselItemStyle}
                            onClick={handleImageClick}
                        />
                    </Carousel.Item>
                ))}
            </Carousel>

            <div className="info-box mt-3 p-3 bg-white text-center">
                <p className="mb-1">Precio: ${organizedRifas[currentIndex]?.precio}</p>
                <p className="mb-0">Fecha de sorteo: {organizedRifas[currentIndex]?.fecha_sorteo}</p>
            </div>
            <Button
                variant="info"
                className="mt-3"
                onClick={() =>
                    capturarRifa(
                        organizedRifas[currentIndex].id,
                        organizedRifas[currentIndex].name,
                        organizedRifas[currentIndex].fecha_sorteo,
                        organizedRifas[currentIndex].precio,
                        organizedRifas[currentIndex].image_medium,
                        organizedRifas[currentIndex].porcentaje_recaudo,
                        organizedRifas[currentIndex].numero_resolucion_rifa,
                        organizedRifas[currentIndex].operador_efectivo,
                        organizedRifas[currentIndex].operador_tarjeta
                    )
                }
            >
                Comprar
            </Button>

            <div style={thumbnailContainerStyle}>
                {organizedRifas.map((rifa, index) => (
                    <img
                        key={index}
                        src={getImageSrc(rifa)}
                        alt={`Thumbnail ${rifa.name}`}
                        onClick={() => handleSelect(index)}
                        style={{
                            ...thumbnailStyle,
                            transform: currentIndex === index ? 'scale(1.1)' : 'scale(1)',
                            border: currentIndex === index ? '2px solid #007bff' : 'none',
                        }}
                    />
                ))}
            </div>

            <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
                <Modal.Body className="p-0">
                    <img
                        src={getImageSrc(organizedRifas[currentIndex])}
                        alt={`Rifa ${organizedRifas[currentIndex]?.name}`}
                        className="w-100"
                        style={{ objectFit: 'cover' }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseModal}>
                        Cerrar
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};


export default CarruselRifas;
