import React, { Component } from "react";
import Carousel from "react-spring-3d-carousel";
import uuidv4 from "uuid";
import { config } from "react-spring";
import foto1 from '../../../assets/home/fotos-05.jpg';
import foto2 from '../../../assets/home/foto-8.jpg';
import foto3 from '../../../assets/home/PROMOCIONAL.jpg';
import foto4 from '../../../assets/home/quienes-somos.jpg';
import rifa from '../../../assets/home/volante_rifa.jpg';


export default class CarruselHome extends Component {
  state = {
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: true,
    config: config.gentle
  };
  data = [
    {
      content: <img src={rifa} alt="1" />,
      key: uuidv4(),
    },
    {
      content: <img src={foto1} alt="2" />,
      key: uuidv4(),
    },
    {
      content: <img src={foto2} alt="3" />,
      key: uuidv4(),
    },
    {
      content: <img src={foto3} alt="4" />,
      key: uuidv4(),
    },
    {
      content: <img src={foto4} alt="5" />,
      key: uuidv4(),
    },
  ].map((slide, index) => {
    return { ...slide, onClick: () => this.setState({ goToSlide: index }) };
  });
  
  componentDidMount(){
     
  }
  componentWillUnmount(){

  }
  render() {
    return (
      <div className="espacio" style={{ width: "90%", height: "500px", margin: "0 auto" }}>
        <Carousel
          slides={this.data}

          goToSlide={this.state.goToSlide}
          offsetRadius={this.state.offsetRadius}
          showNavigation={this.state.showNavigation}
          animationConfig={this.state.config}
        />
      </div>
    );
  }
}


