import React, { useState, useEffect, useRef, Fragment } from 'react';

import Select from "react-select";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { getCities, } from '../../../services/pay.service';
import { capitalize, map } from 'lodash';
import card from '../../../assets/pagos/tarjeta_movil.png'
//import { newLogin } from '../../../services/login.service';
import { payMenCARD } from '../../../services/pay.service';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import '../../../assets/pagos/card_movil.css';
import './card.css';
import { LoadingMovilGo } from '../../../shared/components/loadingMovilGo';
import { ModalNotificationTransaction } from '../../../shared/components/modalNotificationTransaction';
export const PayCardScreen = (props) => {
    const { visible, product, userLogin, closeModal } = props;
    const [digitosLectura, setDigitosLectura] = useState([]);
    const [fecha, setFecha] = useState([]);
    //const [cardName, setCardName] = useState('')
    const mensaje = "Este campo es obligatorio"
    const [modalNotification, setModalNotification] = useState(false)
    const [cardError, setCardError] = useState(false)
    const [departments, setDepartments] = useState([])
    const [allCities, setAllCities] = useState([])
    const [cities, setCities] = useState([])
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [selectedDepartment, setSelectedDepartment] = useState({ value: -1, label: 'Selecion un Departamento' })
    const [selectedCity, setselectedCity] = useState({ value: -1, label: 'Selecion una Ciudad' })
    const [response, setResponse] = useState({})
    const [date, setDate] = useState();
    const [ip, setIp] = useState()
    const [focusedField, setFocusedField] = useState(null); 
    const [currentCardBackground, setCurrentCardBackground] = useState(Math.floor(Math.random() * 25 + 1));
    const [cardName, setCardName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [cardMonth, setCardMonth] = useState('');
    const [cardYear, setCardYear] = useState('');
    const [cardCvv, setCardCvv] = useState('');
    const [isCardFlipped, setIsCardFlipped] = useState(false);
    const [focusElementStyle, setFocusElementStyle] = useState(null);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const minCardYear = new Date().getFullYear();
    const amexCardMask = '#### ###### #####';
    const otherCardMask = '#### #### #### ####';
    const [cardNumberTemp, setCardNumberTemp] = useState(otherCardMask);

    const documentOptions = [
        { value: 'CC', label: 'Cedula' },
        { value: 'CE', label: 'Cedula Extranjera' },
        { value: 'NIT', label: 'Número de Identificación Tributaria' },
        { value: 'TI', label: 'Tarjeta de Identidad' },
        { value: 'PP', label: 'Pasaporte' },
        { value: 'IDC', label: 'Identificador único De Cliente' },
        { value: 'CEL', label: 'Línea Móvil' },
        { value: 'RC', label: 'Registro Civil' },
        { value: 'DE', label: 'Documento de Identificación Extranjero' }
    ]
    const [data, setData] = useState({
        fullName: '',
        email: '',
        contactPhone: '',
        street1: '',
        street2: '',
        documentType: { value: -1, label: 'Seleccione un Documento' },
        dniNumber: '',
        postalCode: '',
        personTypeSelected: '',
        selectedDepartment: { value: -1, label: 'Selecion un Departamento' },
        selectedCity: { value: -1, label: 'Selecion una Ciudad' }
    })
    const [error, setError] = useState({
        errorFullName: false,
        errorEmail: false,
        errorContactPhone: false,
        errorStreet1: false,
        errorStreet2: false,
        errorDniNumber: false,
        errorPostalCode: false,

    })

    useEffect(() => {
        loadData()
        setCardNumberTemp(otherCardMask);
    }, [])

    const getCardType = () => {
        const number = cardNumber;
        if (/^4/.test(number)) return 'visa';
        if (/^(34|37)/.test(number)) return 'amex';
        if (/^5[1-5]/.test(number)) return 'mastercard';
        if (/^6011/.test(number)) return 'discover';
        if (/^9792/.test(number)) return 'troy';
        return 'visa'; // Tipo predeterminado
    };

    // Generar la máscara de número de tarjeta
    const generateCardNumberMask = () => {
        return getCardType() === 'amex' ? amexCardMask : otherCardMask;
    };

    const formatCardNumber = (number) => {
        const type = getCardType();
        let mask = type === 'amex' ? amexCardMask : otherCardMask;

        let formattedNumber = '';
        let index = 0;

        for (let i = 0; i < mask.length; i++) {
            if (mask[i] === '#') {
                if (index < number.length) {
                    formattedNumber += number[index];
                    index++;
                }
            } else {
                formattedNumber += mask[i];
            }
        }

        return formattedNumber.trim();
    };

    const handleCardNumberChange = (e) => {
        const rawValue = e.target.value.replace(/\D/g, ''); // Elimina caracteres no numéricos
        setCardNumber(formatCardNumber(rawValue));
    };


    // Generar una lista de meses
    const months = Array.from({ length: 12 }, (_, i) => i + 1).map(n => ({
        value: n < 10 ? `0${n}` : n.toString(),
        label: n < 10 ? `0${n}` : n.toString()
    }));

    // Generar una lista de años (por ejemplo, los próximos 12 años)
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 12 }, (_, i) => currentYear + i).map(year => ({
        value: year.toString(),
        label: year.toString()
    }));

    // Funciones para enfocar/desenfocar inputs
    const focusInput = (field) => {
        setFocusedField(field);
    };

    const blurInput = () => {
        setFocusedField(null);
    };


    const loadData = async () => {

        //console.log("productis del  card",product)
        getCities(userLogin, async (flag, res) => {
            ////console.log("flag del getcities",flag)
            ////console.log("res del getcities",res)
            let cityOptions = []
            let departmentsOptions = []

            if (flag) {
                res.departamentos.forEach((element) => {
                    departmentsOptions.push({ value: element.stateId, label: element.nombre })
                    departmentsOptions.sort((a, b) => {
                        return a.label < b.label ? -1 : 1;
                    });
                })
                res.cuidades.forEach((element) => {
                    cityOptions.push({ label: element.cityName, value: element.cityId, stateId: element.stateId, })
                    cityOptions.sort((a, b) => {
                        return a.label < b.label ? -1 : 1;
                    });
                })
                setDepartments(departmentsOptions)
                setAllCities(cityOptions)
                setLoading(false)
                getDate()
                createDigitos()

            } else {
                setLoading(false)
                if (res.message === 'No UID returned from authentication.') {
                    /* newLogin((flag)=>{
                        
                        if(flag){
                            loadData()
                        }
                    }) */
                } else {
                    alert("Error", "No se pudo realizar la consulta");
                    closeModal(false)
                }
                //alert("Error", "No se pudo realizar la consulta");

            }
        })

    }
    const createDigitos = () => {
        let digitos = [];
        let fecha = []
        for (let index = 0; index < 16; index++) {
            if ((index + 1) % 4 === 0) {
                digitos.push({
                    style: {
                        ...styles.input, marginRight: 8,
                    }
                })
            } else {
                digitos.push({
                    style: {
                        ...styles.input, marginRight: 2
                    }
                })
            }
        }
        for (let index = 0; index < 4; index++) {

            if (index === 1) {
                fecha.push({
                    style: {
                        ...styles.fecha, marginRight: 8,
                    }
                })
            } else {
                fecha.push({
                    style: {
                        ...styles.fecha, marginRight: 2
                    }
                })
            }
        }
        setDigitosLectura(digitos);
        setShow(true)
    }


    const getDate = () => {
        const d = new Date();
        let month = (d.getMonth() + 1)
        let day = d.getDate()
        let horas = d.getHours()
        let min = d.getMinutes()
        let seg = d.getSeconds()

        let fecha = d.getFullYear() + "-" + month + "-" + day
        let hora = horas + ':' + min + ':' + seg;
        let date = fecha + " " + hora
        setDate(date)

    }
    /* const getCardType = (number) => {
        // visa
        var re = new RegExp("^4");
        if (number.match(re) != null)
            return "Visa";

        // Mastercard 
        // Updated for Mastercard 2017 BINs expansion
        if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number))
            return "Mastercard";

        // AMEX
        re = new RegExp("^3[47]");
        if (number.match(re) != null)
            return "AMEX";

        // Discover
        re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
        if (number.match(re) != null)
            return "Discover";

        // Diners
        re = new RegExp("^36");
        if (number.match(re) != null)
            return "Diners";

        // Diners - Carte Blanche
        re = new RegExp("^30[0-5]");
        if (number.match(re) != null)
            return "Diners - Carte Blanche";

        // JCB
        re = new RegExp("^35(2[89]|[3-8][0-9])");
        if (number.match(re) != null)
            return "JCB";

        // Visa Electron
        re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
        if (number.match(re) != null)
            return "Visa Electron";

        return "";

    } */
    const sendToOdoo = () => {
        if (verifyField(true, true, true, true, true, true, true, true, true, true, true)) {
            setLoading(true)
            let digitos = cardNumber.toString().replace(/\s+/g, '');
            let expirationDate = cardMonth && cardYear ? `${cardYear}/${cardMonth}` : 'YYYY/MM';
            let typecard = getCardType(cardNumber)
            const payproduct = {
                "tx_value": product.atributes.precio ? product.atributes.precio : product.atributes.valorPagar,
                "currency": "COP",
                "fullName": data.fullName,
                "emailAddress": data.email,
                "contactPhone": data.contactPhone,
                "dniNumber": data.dniNumber,
                "street1": data.street1,
                "street2": data.street2,
                "city": data.selectedCity.label,
                "state": data.selectedDepartment.label,
                "country": "CO",//por ahora se quema cuando sean pagos desde otros paises se tiene que selecionar 
                "postalCode": data.postalCode,
                "phone": data.contactPhone,
                "number": digitos,
                "cedulatarjeta": data.dniNumber,
                "securityCode": cardCvv,
                "expirationDate": expirationDate,
                "name": cardName.toUpperCase(),
                "paymentMethod": typecard.toUpperCase(),//calcular que tipo de tarjeta es 
                "paymentCountry": "CO",
                "ipAddress": '',
                "userAgent": "Movilgo APP " + date,
                "INSTALLMENTS_NUMBER": 1,
                "product": product
            }
            console.log("product del card",payproduct)
            payMenCARD(payproduct, userLogin, async (flag, res) => {
                setLoading(false)
                let response = JSON.parse(res)
                if (flag) {
                    if (response[0]) {
                        if (response[0].errores) {
                            if (response[0].errores.error) {
                                alert(response[0].errores)
                            } else {
                                alert(response[0].errores)
                            }

                        } else if (response[0].error) {
                            alert("Se presento un error al realizar la compra")
                        }

                    } else if (response.errores) {
                        if (response.errores.observacion) {
                            alert(response.errores.observacion)
                        } else if (response.errores.error) {
                            alert(response.errores.error)
                        }



                    } else if (response.error) {
                        alert(response.error)
                    } else {
                        //console.log("ingreso al else ")
                        setModalNotification(true)
                        setResponse(response)
                        const success = () => toast.success("Recarga comprada exitosamente");
                        success();
                    }

                    //const isOk =await pdfPay(localProduct,res)
                } else {
                    alert("se presento un error al realizar la transaccion, vuelva a intentarlo")
                }

            })
        }

    }

    const verifyField = (fullName, email, phone, street, street2, dni, postalCode, documentType, deparment, city, card) => {
        let numbercard = cardNumber
        let fechacard = cardMonth && cardYear 

        if (fullName) {

            if (data.fullName === "") {
                setError({ ...error, errorFullName: true })
                return false
            } else {
                setError({ ...error, errorFullName: '' })

            }
        }

        if (email) {
            if (!data.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                setError({ ...error, errorEmail: 'Formato del correo incorrecto' })
                return false
            } else {
                setError({ ...error, errorEmail: false })

            }
        }
        if (phone) {
            if (data.contactPhone === "") {
                setError({ ...error, errorContactPhone: true })
                return false
            } else if (data.contactPhone.length < 7) {
                setError({ ...error, errorContactPhone: 'El numero es demasiado corto' })
                return false
            } else {
                setError({ ...error, errorContactPhone: false })

            }
        }
        if (street) {
            if (data.street1 === "") {
                setError({ ...error, errorStreet1: true })
                return false
            } else {
                setError({ ...error, errorStreet1: false })

            }
        }
        if (dni) {
            if (data.dniNumber === "") {
                setError({ ...error, errorDniNumber: true })
                return false
            } else {
                setError({ ...error, errorDniNumber: false })

            }
        }
        if (postalCode) {
            if (data.postalCode == "") {
                setError({ ...error, errorPostalCode: true })
                return false
            } else {
                setError({ ...error, errorPostalCode: false })

            }
        }
        if (documentType) {
            if (data.documentType.value === "-1") {
                setError({ ...error, errorDocumentType: true })
                return false
            } else {
                setError({ ...error, errorDocumentType: false })

            }
        }

        if (deparment) {
            if (data.selectedDepartment.value == -1) {
                setError({ ...error, errorDeparment: true })
                return false
            } else {
                setError({ ...error, errorDeparment: false })

            }
        }
        if (city) {
            if (data.selectedCity.value === -1) {
                setError({ ...error, errorCity: true })
                return false
            } else {
                setError({ ...error, errorCity: false })

            }
        }

        if (street2) {
            if (data.street2 === "") {
                setError({ ...error, errorStreet2: true })
                return false
            } else {
                setError({ ...error, errorStreet2: false })

            }
        }
        if (card) {
            if (numbercard.includes(undefined) || fechacard == false|| cardName === '' || cardCvv == '' ) {
                setCardError(true)
                return false
            } else {
                setCardError(false)
            }

        }

        return true

    }

    const departmentSelect = (department) => {
        ////console.log("department",data)
        setCities([])
        let cityOptions = []
        allCities.forEach((element) => {
            if (parseInt(department.value) === parseInt(element.stateId)) {
                //////console.log(element)
                cityOptions.push({ label: element.label, value: element.value, stateId: element.stateId, })
                cityOptions.sort((a, b) => {
                    return a.label < b.label ? -1 : 1;
                });
            }
        })

        setCities(cityOptions)

    }

    return (
        <Modal
            style={{ opacity: 1 }}
            size='md'
            show={visible}/* props.visible */
        >
            <Modal.Header className='header'  >
                <Modal.Title className={'defaultTitle'} style={{ color: 'white' }}>Pago con Tarjeta </Modal.Title>
                <Button onClick={() => { closeModal(false, false) }} type="button" style={{ backgroundColor: 'rgba(138,221,45,0)', border: 'none' }}>
                    <FontAwesomeIcon color={'white'} className="mr-2 align-self-center" icon={faTimes} />
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Col md={12} xs={12} sm={12} lg={12} >
                    <div className='altura'>
                        {show &&
                            <div>

                                <div className="wrapper" id="app">
                                    <div className="card-form">
                                        <div className="card-list">
                                            <div className={`card-item ${isCardFlipped ? '-active' : ''}`}>
                                                <div className="card-item__side -front">
                                                    <div className="card-item__focus" style={focusElementStyle}></div>
                                                    <div className="card-item__cover">
                                                        <img
                                                            src={`https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/${currentCardBackground}.jpeg`}
                                                            className="card-item__bg"
                                                            alt="Card background"
                                                        />
                                                    </div>
                                                    <div className="card-item__wrapper">
                                                        <div className="card-item__top">
                                                            <img
                                                                src={`https://img.icons8.com/?size=100&id=kiERC418GbAI&format=png&color=000000`}
                                                                className="card-item__chip"
                                                                alt="Chip"
                                                            />
                                                            <div className={`card-item__type slide-fade-up ${focusedField === 'cardType' ? 'focused' : ''}`}>
                                                                <img
                                                                    src={`https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/${getCardType()}.png`}
                                                                    alt="Card type"
                                                                    className="card-item__typeImg"
                                                                />
                                                            </div>
                                                        </div>
                                                        <label className={`card-item__number ${focusedField === 'cardNumber' ? 'focused' : ''}`}>
                                                            {generateCardNumberMask().split('').map((char, index) => (
                                                                <span key={index} className="card-item__numberItem">
                                                                    {cardNumber[index] || char}
                                                                </span>
                                                            ))}
                                                        </label>
                                                        <div className="card-item__content">
                                                            <label className={`card-item__info ${focusedField === 'cardName' ? 'focused' : ''}`}>
                                                                <div className="card-item__holder">Nombre</div>
                                                                <div className="card-item__name">{cardName || 'Full Name'}</div>
                                                            </label>
                                                            <div className={`card-item__date ${focusedField === 'cardDate' ? 'focused' : ''}`}>
                                                                <label className="card-item__dateTitle">Expira</label>
                                                                <label className="card-item__dateItem">
                                                                    {cardMonth || 'MM'} / {cardYear ? cardYear.slice(2) : 'YY'}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card-item__side -back">
                                                    <div className="card-item__cover">
                                                        <img
                                                            src={`https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/${currentCardBackground}.jpeg`}
                                                            className="card-item__bg"
                                                            alt="Card background"
                                                        />
                                                    </div>
                                                    <div className="card-item__band"></div>
                                                    <div className="card-item__cvv">
                                                        <div className="card-item__cvvTitle">CVV</div>
                                                        <div className="card-item__cvvBand">
                                                            {cardCvv.split('').map((char, index) => (
                                                                <span key={index}>*</span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-form__inner">
                                            <div className="card-input">
                                                <label htmlFor="cardNumber" className="card-input__label">Número de la tarjeta</label>
                                                <input
                                                    type="text"
                                                    id="cardNumber"
                                                    className="card-input__input"
                                                    value={cardNumber}
                                                    onChange={handleCardNumberChange}
                                                    onFocus={() => focusInput('cardNumber')}
                                                    onBlur={blurInput}
                                                />
                                            </div>

                                            <div className="card-input">
                                                <label htmlFor="cardName" className="card-input__label">Nombre en tarjeta</label>
                                                <input
                                                    type="text"
                                                    id="cardName"
                                                    className="card-input__input"
                                                    value={cardName}
                                                    onChange={(e) => setCardName(e.target.value)}
                                                    onFocus={() => focusInput('cardName')}
                                                    onBlur={blurInput}
                                                />
                                            </div>

                                            <div className="card-form__row">
                                                <div className="card-form__col">
                                                    <div className="card-form__group">
                                                        <label htmlFor="cardMonth" className="card-input__label">Fecha de Expiración</label>
                                                        <div className="card-form__row">
                                                            <select
                                                                id="cardMonth"
                                                                className="card-input__input"
                                                                value={cardMonth}
                                                                onChange={(e) => setCardMonth(e.target.value)}
                                                                onFocus={() => focusInput('cardDate')}
                                                                onBlur={blurInput}
                                                            >
                                                                <option value="" disabled>Mes</option>
                                                                {months.map(month => (
                                                                    <option key={month.value} value={month.value}>
                                                                        {month.label}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <select
                                                                id="cardYear"
                                                                className="card-input__input card-input__input--year"
                                                                value={cardYear}
                                                                onChange={(e) => setCardYear(e.target.value)}
                                                                onFocus={() => focusInput('cardDate')}
                                                                onBlur={blurInput}
                                                            >
                                                                <option value="" disabled>Año</option>
                                                                {years.map(year => (
                                                                    <option key={year.value} value={year.value}>
                                                                        {year.label}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-form__col -cvv">
                                                    <div className="card-input">
                                                        <label htmlFor="cardCvv" className="card-input__label">CVV</label>
                                                        <input
                                                            type="text"
                                                            className="card-input__input"
                                                            id="cardCvv"
                                                            value={cardCvv}
                                                            onChange={(e) => setCardCvv(e.target.value)}
                                                            onFocus={() => setIsCardFlipped(true)}
                                                            onBlur={() => setIsCardFlipped(false)}
                                                            maxLength={4}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {cardError && <p style={{ fontSize: 12, color: 'red' }}> {mensaje}<br></br></p>}
                                <Form style={{ width: '100%', height: '100%' }}>
                                    <div style={{ opacity: 1, marginBottom: '10%' }}>
                                        <Form.Group className={'defaultTitle'}>
                                            <Form.Control
                                                placeholder="Nombre Completo"
                                                onBlur={() => verifyField(true)}
                                                value={data.fullName}
                                                onChange={(event) => setData({ ...data, fullName: event.target.value })}
                                                type="text"
                                            />
                                            <br />
                                            {error.errorFullName && <p style={{ fontSize: 12, color: 'red' }}> {mensaje}<br></br></p>}

                                            <Form.Control
                                                placeholder="Email"
                                                onBlur={() => verifyField(false, true)}
                                                value={data.email}
                                                onChange={(event) => setData({ ...data, email: event.target.value })}
                                                type="email"
                                            />
                                            <br />
                                            {error.errorEmail && <p style={{ fontSize: 12, color: 'red' }}> {mensaje}<br></br></p>}

                                            <Form.Control
                                                placeholder="Celular"
                                                onBlur={() => verifyField(false, false, true)}
                                                value={data.contactPhone}
                                                onChange={(event) => setData({ ...data, contactPhone: event.target.value.replace(/\D/, '') })}
                                                arial-label="Amount (to the nearest dollar)"
                                                maxLength={10}
                                            />
                                            <br />
                                            {error.errorContactPhone && <p style={{ fontSize: 12, color: 'red' }}>  {mensaje}<br></br></p>}

                                            <Form.Control
                                                placeholder="Direccion"
                                                onBlur={() => verifyField(false, false, false, true)}
                                                value={data.street1}
                                                onChange={(event) => setData({ ...data, street1: event.target.value })}
                                                type="text"
                                            />
                                            <br />
                                            {error.errorStreet1 && <p style={{ fontSize: 12, color: 'red' }}> {mensaje}<br></br></p>}
                                            <Form.Control
                                                placeholder="Otra Direccion"
                                                onBlur={() => verifyField(false, false, false, false, true)}
                                                value={data.street2}
                                                onChange={(event) => setData({ ...data, street2: event.target.value })}
                                                type="text"
                                            />
                                            <br />
                                            {error.errorStreet2 && <p style={{ fontSize: 12, color: 'red' }}> {mensaje} <br></br></p>}
                                            <Fragment>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    value={data.documentType}
                                                    isDisabled={false}
                                                    isLoading={false}
                                                    isClearable={true}
                                                    isRtl={false}
                                                    isSearchable={true}
                                                    name="color"
                                                    options={documentOptions}
                                                    onChange={(documentType) => { setData({ ...data, documentType }) }}
                                                />
                                            </Fragment>
                                            <br />

                                            {error.errorDocumentType && <p style={{ fontSize: 12, color: 'red' }}> {mensaje} <br></br></p>}

                                            <Form.Control
                                                placeholder="Numero De Documento"
                                                arial-label="Amount (to the nearest dollar)"
                                                onBlur={() => verifyField(false, false, false, false, false, true, false, false, false, false)}
                                                value={data.dniNumber}
                                                onChange={(event) => setData({ ...data, dniNumber: event.target.value.replace(/\D/, '') })}
                                                maxLength={10}
                                            />
                                            <br />
                                            {error.errorDniNumber && <p style={{ fontSize: 12, color: 'red' }}> {mensaje} <br></br></p>}
                                            <Form.Control
                                                placeholder="Codigo Postal"
                                                arial-label="Amount (to the nearest dollar)"
                                                onBlur={() => verifyField(false, false, false, false, false, false, true, false, false, false)}
                                                value={data.postalCode}
                                                maxLength={6}
                                                onChange={(event) => setData({ ...data, postalCode: event.target.value.replace(/\D/, '') })}

                                            />
                                            <br />
                                            {error.errorPostalCode && <p style={{ fontSize: 12, color: 'red' }}>{mensaje} <br></br></p>}


                                            <Fragment>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    value={data.selectedDepartment}
                                                    isDisabled={false}
                                                    isLoading={false}
                                                    isClearable={true}
                                                    isRtl={false}
                                                    isSearchable={true}
                                                    name="color"
                                                    options={departments}
                                                    onChange={(selectedDepartment) => {
                                                        setData({ ...data, selectedDepartment });
                                                        departmentSelect(selectedDepartment)
                                                    }}
                                                />
                                            </Fragment>
                                            <br />
                                            {error.errorDeparment && <p style={{ fontSize: 12, color: 'red' }}>{mensaje}<br></br></p>}
                                            <Fragment>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    value={data.selectedCity}
                                                    isDisabled={false}
                                                    isLoading={false}
                                                    isClearable={true}
                                                    isRtl={false}
                                                    isSearchable={true}
                                                    name="color"
                                                    options={cities}
                                                    onChange={(selectedCity) => { setData({ ...data, selectedCity }); }}
                                                />
                                            </Fragment>
                                            <br />
                                            {error.errorCity && <p style={{ fontSize: 12, color: 'red', marginBottom: '10%' }}>{mensaje}<br></br></p>}

                                        </Form.Group>
                                    </div>
                                </Form>
                            </div>}

                    </div>
                </Col>


            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { closeModal(false) }}>
                    Cancelar
                </Button>
                <Button
                    variant="info"
                    onClick={() => sendToOdoo()}
                /* disabled={!identificacion || !nombre || !apellido || !direccion || !celular || (!correo.test(email) ? email : "")} */
                >
                    Comprar
                </Button>
            </Modal.Footer>
            <div style={{ marginLeft: 130 }}>
                {modalNotification && <ModalNotificationTransaction
                    closeModal={(flag, dataResponse) => {
                        closeModal(flag, dataResponse)
                        setModalNotification(flag)
                    }}
                    reponse={response}
                    message={"Su Transaccion fue exitosa"} />}

                {loading && <LoadingMovilGo />}
            </div>

        </Modal>

    )

}
const styles = {
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        alignSelf: 'center',
        marginVertical: 10,
        color: 'white'
    },
    input: {
        backgroundColor: 'transparent',
        width: 20,
        borderBottomColor: 'white',



    }, fecha: {
        color: 'white',
        backgroundColor: 'transparent',
        fontSize: 15,
        height: '95%',
        borderBottomWidth: 1,
        width: 30,
        borderBottomColor: 'white',
        borderBottomWidth: 1,

    },
    input2: {
        marginLeft: 5,
        borderBottomColor: 'white',
        borderBottomWidth: 1,
        width: 17
    },
    searchPiker: {
        width: '95%',
        alignSelf: 'center',
        marginTop: 8
    }
}