
import React, { useEffect, useState } from 'react'
import { getRifaResume } from '../../../services/rifa.service';
import './styles.css'
import { useParams } from 'react-router-dom';

export const ShowRifa = () => {
  const [img, setImg] = useState("");
  const [imgPromotional, setImgPromotional] = useState("");
  const [name, setName] = useState("");
  const [numTicket, setNumTicket] = useState("");

  let { t } = useParams();

  useEffect(() => {
    const callback = (data, flag) => {
      if (data) {
        setImg(data.datos[0].boleta);
        setNumTicket(data.datos[0].numero_boleta);
        setName(data.datos[0].persona);
        setImgPromotional(data.datos[0].rutaimagenpromocional)
      }
    }
    getRifaResume(t, callback);
  }, [t])



  return (
    <>
      {img && <div className='container' style={{ backgroundColor: '#fafafafa', overflow: 'hidden', padding: 0 }}>
        <div className=" row" style={{}}>
          <div className="col" style={{ padding: 0, }}>
            <img alt="" src={require("../../../assets/images/movilgositoMano.png")}
              style={{ maxWidth: '75%', maxHeight: '75%' }}></img>
          </div>
          <div className="col" style={{}} >
            <div className=" row">
              <div className="col" style={{}}>
                <p style={{ color: '#07A2BA', fontWeight: 'bold', fontSize: '3vw' }}> ¡Hola!</p>

                <h3 style={{ color: '#07A2BA', fontWeight: 'bold', fontSize: '2vw' }}>{name}</h3>
              </div>
              <div className="col-xs-8" >
                <div style={{
                  backgroundColor: 'rgba(147,206,222,0.5)',
                  borderWidth: '2px', width: '15vh', position: 'relative',
                  height: '15vh', borderRadius: '50%', marginTop: -8,

                }}>
                  <img alt="" src={require("../../../assets/images/logo.png")}
                    className="img-responsive" style={{ maxHeight: '18%', marginTop: '35%', }}></img>
                </div>
                <img alt="" src={require("../../../assets/images/puntos4.png")}
                  className="img-responsive"
                  style={{ maxHeight: '35%', maxWidth: '35%', position: 'absolute', right: '10%', top: '110%' }}></img>
                <img alt="" src={require("../../../assets/images/punto2.png")}
                  className="img-responsive"
                  style={{ maxHeight: '10%', maxWidth: '10%', position: 'absolute', right: '55%', top: '130%' }}></img>
              </div>
            </div>
            <div className=''
              style={{
                borderStyle: 'outset',
                borderWidth: '2px',
                borderColor: '#07A2BA', borderRadius: '3%',
                marginTop: '10%',
                marginRight: '4%'

              }}>
              <div >
                <img alt="" src={'data:image/jpeg;base64,' + img}
                  style={{ borderRadius: '5%', maxHeight: '100%', maxWidth: '100%', minHeight: '10%', minWidth: '10%' }}
                ></img>
                <img alt="" src={require("../../../assets/images/nuestra-rifa.png")}
                  className='to'
                  style={{ maxWidth: '70%', maxHeight: '70%', position: 'absolute', left: '-20%', top: '78%' }}
                ></img>
                <img alt="" src={require("../../../assets/images/puntos3.png")}
                  style={{
                    maxHeight: '35%', maxWidth: '35%',
                    position: 'absolute', top: '80%', left: '-60%'
                  }}></img>
                <img alt="" src={require("../../../assets/images/puntos1.png")}
                  style={{
                    maxHeight: '35%', maxWidth: '35%',
                    position: 'absolute', top: '120%', left: '-45%'
                  }}></img>
                <div
                  className='to'
                  style={{
                    backgroundColor: 'white',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: 'red',
                    borderRadius: '10%',
                    width: '15%',
                    height: '4%'

                  }}>
                  <p className="letra" style={{ color: 'red' }}>
                    <b className="letra" style={{ color: 'red' }}> N°</b>
                    {numTicket}
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="row">
          <div className="col" style={{
            borderStyle: 'outset',
            borderWidth: '2px',
            borderColor: '#07A2BA', borderRadius: '3%',
            marginTop: '20%', marginLeft: '5%'
          }}>
            <img alt="" src={imgPromotional}
              style={{ borderRadius: '5%', maxHeight: '100%', maxWidth: '100%', minHeight: '10%', minWidth: '10%' }}></img>
          </div>
          <div className="col">
            <div style={{ marginTop: '70%' }}>
              <img alt="" src={require("../../../assets/images/punto-GO.png")}
                style={{ maxHeight: '50%', maxWidth: '50%', marginLeft: '15%' }}></img>
              <div style={{ marginTop: '20%' }}>
                <a href="https://www.facebook.com/movilgored/"><img alt=""
                  src={require("../../../assets/images/facebookR.png")} style={{ width: 25 }}
                  className="img-responsive"></img></a>
                <a href="https://instagram.com/movilgored?igshid=1613fpkvei2i2"><img alt=""
                  src={require("../../../assets/images/instagramR.png")} style={{ width: 25 }}
                  className="img-responsive"></img></a>
                <br />
                <a className="letra" href="https://movilgo.com.co/" >www.movilgo.com.co</a>
              </div>

            </div>
          </div>
        </div>
        <div className='row'>
          <div className="col"  ></div>
          <div className="col"  ></div>
          <div className="col" style={{ marginTop: '3%', color: '#07A2BA', marginRight: '8%' }}>
            <p className="letra"  >Capitalia Centro Empresarial<br></br>
                Carrera 23 # 62-39 Ofc 404 B<br></br>
                3216435009 - 3168194210
                </p>
          </div>
        </div>
        <div className="row">
          <div className="col" style={{ textAlign: 'justify', color: '#07A2BA', width: '50%', }}>
            <p className="letra" style={{ padding: 8 }}>
              En virtud de lo establecido por la disposición de Protección de Datos
              Personales, usted tiene derecho a solicitar al emisor de este mensaje
              la rectificación, actualización, inclusión o supresión de los datos personales
              incluidos en su base de datos de contactos, listas o cadenas de mensajes en los
              cuales usted se encuentre.
                            </p>
            <div style={{ padding: 8 }}>
              <u><a className="letra" href="http://45.56.65.237:8090/terminos">Términos y condiciones</a></u>
              <u style={{ marginLeft: 10 }}><a className="letra" href="http://45.56.65.237:8090/Political">Política de Privacidad</a></u>
            </div>

          </div>
          <div className="col"></div>
        </div>
        <div className="row" >
          <div className="col"></div>
          <div className="col">
            <img alt="" src={require("../../../assets/images/footer.png")}
              style={{ maxHeight: '100%', maxWidth: '100%', }}></img>
          </div>
        </div>
      </div>}
    </>
  )
}