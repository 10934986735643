import React, { PureComponent } from 'react';
import Routes from './routes';
import HeaderDefault from './shared/components/header';
import Footer from './shared/components/footer'
import { ToastProvider } from 'react-toast-notifications'
import Odoo from 'odoo-xmlrpc';
import { OdooConfig } from './utilities/odoo-config';
import { setData } from './services/login.service';
import { Desencrypt } from './utilities/cipher'
import fondo from './assets/fondo.png'

class App extends PureComponent {
  constructor() {
    super();
    this.state = {
      products: {}
    }
  }
  
  getListProducts = (products, flag) => {
    if (flag) { //console.log("PRODUCTS -App.js", products.products)
      this.setState({ products: products.products });
    }
  }

  initialiceData = async () => {
    const config = await OdooConfig();
    const data = await Desencrypt();
    if (data) { //console.log("APP - DATA", data)
      const permiso = localStorage.getItem('Permission');
      if (permiso !== 'cobrador') {  //console.log("APP - permiso", permiso)
        const odoo = new Odoo({
          url: config.host,
          port: config.port,
          db: config.database,
          username: data.user,
          password: data.password
        });
        
        setData(odoo, this.getListProducts, data);
      } //console.log("APP - permiso", permiso)
    }
  }

  componentDidMount = () => {
    /* const productos = localStorage.getItem('listProducts');
    const listProducts = JSON.parse(productos);
    this.setState({ products: listProducts });
    console.log("listProducts", listProducts); */
    this.initialiceData();

  }
  render() {
    return (
      <ToastProvider>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <style>
          {` .margen { min-height: 79.4vh; text-align: center; margin-top: 10px; min-width: 10vh; } `
          }
        </style>

        <HeaderDefault init={this.initialiceData} />
        <div className="margen" style={{ height:'100%', backgroundImage: `url(${fondo})` }}>
          {/* <img className="img-responsive" style={{ left: '0%', top: '15%', width: '15%', position: 'absolute',
            transform: 'rotate(10deg) scale(0.8)'  }} src={movilgoFrente2}></img> */}
          <Routes products={this.state.products} />
        </div>
        <Footer />

      </ToastProvider>
    );
  }
}

export default App;
