import React, { PureComponent } from 'react';
import { ToastContainer } from 'react-toastify';
import { LoadingMovilGo } from '../../../shared/components/loadingMovilGo';
import { CarruselExequiales } from './carousel-exequiales';

export class ListExequiales extends PureComponent {

    constructor(props) {
        super(props);
        this.exequiales = this.props.exequiales;

        this.state = {
            loading: true,
            visible: false,
            selectedRifa: {
                id: -1,
                image_medium: null,
                name: '',
                precio: '',
                fecha_sorteo: '',

            }
        }
      
    }
    componentDidMount = () => {
        //console.log("this.exequal",this.exequiales)
        if (this.props.exequiales) {
            this.setState({ loading: false })
        }/* else{
            
            this.setState({ loading: false })
            const error = () => toast.error("No tienes este producto disponible");
            error();
        } */
    }
    render() {
        return (
            <>
                <style>
                    {`
                .my-auto {
                    margin-top: auto;
                    margin-bottom: auto;
                } 
                .reloadsCard {
                    box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.17);
                    border-radius: 1em;
                    margin: 5px;
                }
                `}
                </style>
                {this.state.loading && <LoadingMovilGo />}
                <ToastContainer />
                <h1 className={'defaultTitle'}>Seguros</h1>

                <div style={{ padding: '30px 2% 0px 2%' }}>

                    <CarruselExequiales exequiales={this.exequiales} />

                </div>
            </>

        )
    }
}


